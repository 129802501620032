import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import * as Tabs from '@radix-ui/react-tabs';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Checkbox from '@radix-ui/react-checkbox';
import { Check, Upload, FileIcon, Trash2 } from 'lucide-react';
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoTrashOutline } from "react-icons/io5";
import { FiChevronUp } from "react-icons/fi";
import '../styles/EditAgentPage.css';
import { Callout, IconButton, Button, Switch } from "@radix-ui/themes";
import CategorizedIconDropdown from '../components/CategorizedIconDropdown';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import * as HoverCard from '@radix-ui/react-hover-card';
import AgentDrivePopup from '../components/AgentDrivePopup';
import NotionPopup from '../components/NotionPopup';
import FileBrowser from '../components/FileBrowser';
import CreateAgentChat from '../components/CreateAgentChat';
import { GoPeople } from "react-icons/go";
import { 
  PersonIcon, 
  TargetIcon,
  FileTextIcon,
  MixerHorizontalIcon,
  LightningBoltIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
  ChatBubbleIcon,
  StackIcon,
  SlashIcon,
  BookmarkIcon
} from '@radix-ui/react-icons';

const EditAgentPage = ({ agent }) => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  const topRef = useRef(null);
  const [currentTab, setCurrentTab] = useState('identity');
  const [availableIcons, setAvailableIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [isIconDropdownOpen, setIsIconDropdownOpen] = useState(false);
  const [name, setName] = useState('');
  const [miniDescription, setMiniDescription] = useState('');
  const [description, setDescription] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [aiModel, setAiModel] = useState('');
  const [mood, setMood] = useState(50);
  const [availableModels, setAvailableModels] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isImprovingDescription, setIsImprovingDescription] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [fileProgress, setFileProgress] = useState({});
  const [overallProgress, setOverallProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [selectedGoogleFiles, setSelectedGoogleFiles] = useState([]);
  const [showDrivePopup, setShowDrivePopup] = useState(false);
  const [showNotionPopup, setShowNotionPopup] = useState(false);
  const [savedDriveRefs, setSavedDriveRefs] = useState([]);
  const [savedNotionRefs, setSavedNotionRefs] = useState([]);
  const [selectedDriveRefs, setSelectedDriveRefs] = useState([]);
  const [showDriveRefsPopup, setShowDriveRefsPopup] = useState(false);
  const [urlInput, setUrlInput] = useState('');
  const [showSectionsDropdown, setShowSectionsDropdown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const textareaRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isScrapingUrl, setIsScrapingUrl] = useState(false);
  const [tools, setTools] = useState({
    webSearch: false
  });

  const promptSections = [
    { icon: PersonIcon, name: "Role", description: "Define the Agent persona, its expertise", info: "Example:\nYou are an expert in digital marketing with a focus on social media strategy and SEO optimization." },
    { icon: TargetIcon, name: "Goal", description: "Specify the main goal of the tasks", info: "Example:\nThe goal is to create a detailed marketing plan for a product launch targeting Gen Z" },
    { icon: FileTextIcon, name: "Context", description: "Provide background information", info: "Example:\nThe client operates a small e-commerce business selling eco-friendly products and is planning a Black Friday sale." },
    { icon: MixerHorizontalIcon, name: "Format", description: "Specify specific output format, struture", info: "Example:\nOutput the response as a bulleted list, each point being no more than 20 words." },
    { icon: LightningBoltIcon, name: "Example", description: "Provide what a good output look like", info: "Example:\nA good example of a marketing email: “Subject: [Catchy Title]. Body: Short description + call-to-action.”" },
    { icon: GoPeople, name: "Audience", description: "Describe who will read the output", info: "Example:\nThe audience is mid-level managers in the tech industry who are familiar with agile methodologies." },
    { icon: ListBulletIcon, name: "Step by step Instructions", description: "Guide the agent to do a task step by step", info: "Example:\nYou are an expert in digital marketing with a focus on social media strategy and SEO optimization." },
    { icon: MagnifyingGlassIcon, name: "Reasoning Approach", description: "Provide a logical way to do the tasks", info: "Example:\nStep 1: Identify the main goals. Step 2: Research the audience. Step 3: Write a draft and review." },
    { icon: ChatBubbleIcon, name: "Tone & Style", description: "Set the tone & style for the output", info: "Example:\nThe tone should be concise and formal, with no slang, but approachable for non-technical stakeholders." },
    { icon: StackIcon, name: "Prioritization", description: "Tell your agent what to prioritize", info: "Example:\nFocus first on clarity and then on creativity." },
    { icon: SlashIcon, name: "Out of scope", description: "Provide the limits of the scope", info: "Example:\nAvoid discussing competitor brands or recommending specific tools in the final report." },
    { icon: BookmarkIcon, name: "Resources", description: "Mention any knowledge that can be useful", info: "Example:\nUse the attached PDF report and the provided link to industry benchmarks." }
  ];

  useEffect(() => {
    fetchAgentDetails();
    fetchAvailableModels();
    fetchExistingFiles();
    const icons = getAvailableIcons();
    setAvailableIcons(icons);
    // Scroll to top when component mounts
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [agentId]);

  useEffect(() => {
    fetchExistingDocuments();
  }, []);

  useEffect(() => {
    const fetchSavedRefs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/google/saved-references`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSavedDriveRefs(response.data);
      } catch (error) {
        console.error('Error fetching saved references:', error);
      }
    };

    fetchSavedRefs();
  }, []);

  useEffect(() => {
    const fetchSavedRefs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/notion/saved-references`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSavedNotionRefs(response.data);
      } catch (error) {
        console.error('Error fetching saved Notion references:', error);
      }
    };

    fetchSavedRefs();
  }, []);

  const handleAddSection = () => {
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = textarea.value.substring(0, cursorPosition);
    const textAfterCursor = textarea.value.substring(cursorPosition);
    
    const newValue = textBeforeCursor + '#' + textAfterCursor;
    setDescription(newValue);
    
    const event = {
      target: {
        value: newValue,
        selectionStart: cursorPosition + 1
      }
    };
    
    handleTextareaChange(event);

    textarea.focus();
    textarea.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
  };

  const handleTextareaChange = (e) => {
    const newValue = e.target.value;
    setDescription(newValue);
    
    const lastChar = newValue[e.target.selectionStart - 1];
    if (lastChar === '#') {
      const textarea = textareaRef.current;
      const caretPosition = getCaretCoordinates(textarea, e.target.selectionStart - 1);
      
      if (dropdownRef.current) {
        const rect = textarea.getBoundingClientRect();
        dropdownRef.current.style.position = 'absolute';
        dropdownRef.current.style.top = `${caretPosition.top + 24}px`;
        dropdownRef.current.style.left = `${caretPosition.left}px`;
      }
      
      setShowSectionsDropdown(true);
      setSelectedIndex(0);
    } else {
      setShowSectionsDropdown(false);
    }
  };

  const getCaretCoordinates = (textarea) => {
    const position = textarea.selectionStart;
    
    const front = textarea.value.substring(0, position);
    

    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.top = '0';
    div.style.left = '0';
    div.style.visibility = 'hidden';
    div.style.whiteSpace = 'pre-wrap';
    div.style.height = 'auto';
    div.style.width = textarea.offsetWidth + 'px';
    div.style.fontSize = window.getComputedStyle(textarea).fontSize;
    div.style.fontFamily = window.getComputedStyle(textarea).fontFamily;
    div.style.lineHeight = window.getComputedStyle(textarea).lineHeight;
    div.style.padding = window.getComputedStyle(textarea).padding;
    

    const textContent = document.createTextNode(front);
    const span = document.createElement('span');
    span.appendChild(textContent);
    div.appendChild(span);
    
    document.body.appendChild(div);
    const rect = textarea.getBoundingClientRect();
    const spanRect = span.getBoundingClientRect();
    
    document.body.removeChild(div);
    
    return {
      top: spanRect.top - rect.top,
      left: spanRect.left - rect.left
    };
  };

  const handleKeyDown = (e) => {
    if (!showSectionsDropdown) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => {
          const newIndex = prev > 0 ? prev - 1 : promptSections.length - 1;

          const selectedItem = document.querySelector(`.agent-section-item:nth-child(${newIndex + 2})`); // +2 because of header
          if (selectedItem && dropdownRef.current) {
            selectedItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
          return newIndex;
        });
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => {
          const newIndex = prev < promptSections.length - 1 ? prev + 1 : 0;
          // Scroll into view
          const selectedItem = document.querySelector(`.agent-section-item:nth-child(${newIndex + 2})`); // +2 because of header
          if (selectedItem && dropdownRef.current) {
            selectedItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
          return newIndex;
        });
        break;
      case 'Tab':
      case 'Enter':
        e.preventDefault();
        insertSection(promptSections[selectedIndex].name);
        break;
      case 'Escape':
        e.preventDefault();
        setShowSectionsDropdown(false);
        break;
      default:
        break;
    }
  };

  const insertSection = (sectionName) => {
    if (!textareaRef.current) return;
    const textarea = textareaRef.current;
    const text = textarea.value;
    const cursorPos = textarea.selectionStart;
    
    const lastHashIndex = text.lastIndexOf('#', cursorPos - 1);
    if (lastHashIndex === -1) return;
    
    const beforeHash = text.substring(0, lastHashIndex);
    const afterCursor = text.substring(cursorPos);
    const newText = `${beforeHash}# ${sectionName}\n${afterCursor}`;
    
    setDescription(newText);
    setShowSectionsDropdown(false);
    
    textarea.focus();
    const newCursorPosition = lastHashIndex + sectionName.length + 4;
    setTimeout(() => {
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleSelectDriveRef = async (ref) => {
    try {
      // If it's a folder, we need to get all files in it
      if (ref.type === 'folder') {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/google/folder-contents/${ref.driveId}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSelectedDriveRefs(prev => [...prev, ...response.data]);
      } else {
        setSelectedDriveRefs(prev => [...prev, ref]);
      }
    } catch (error) {
      console.error('Error selecting drive reference:', error);
    }
  };

  const getAvailableIcons = () => {
    const iconContext = require.context('/public/images/all_icons/', false, /\.png$/);
    return iconContext.keys().map(path => path.replace('./', ''));
  };

  // Add this new function with your other fetch functions
  const fetchExistingDocuments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/documents`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setExistingDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleUrlAdd = async () => {
    if (!urlInput.trim()) return;
    
    setIsScrapingUrl(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/scrape-url`,
        { url: urlInput },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const scrapedContent = response.data;
      const fileName = `${new URL(urlInput).hostname}.txt`;
      
      // Create a file-like object with the scraped content
      const file = new File(
        [new Blob([scrapedContent], { type: 'text/plain' })],
        fileName,
        { type: 'text/plain' }
      );

      // Add metadata for tracking the source
      file.isScrapedContent = true;
      file.sourceUrl = urlInput;

      // Initialize progress for this file
      setFileProgress(prev => ({
        ...prev,
        [fileName]: 0
      }));

      // Add to files list
      setFiles(prev => [...prev, file]);

      // Set progress to complete
      setFileProgress(prev => ({
        ...prev,
        [fileName]: 100
      }));

      setUrlInput('');

      toast('🌐 Website scraping is so easy!', {
        theme: "light",
      });

    } catch (error) {
      console.error('Error scraping URL:', error);
      toast.error('Error scraping URL!', {
        theme: "colored",
      });
    } finally {
      setIsScrapingUrl(false);
    }
  };


  const handleAddExistingFile = async (file) => {
    try {

      // Check if file is already selected using exact matching
      const isAlreadySelected = files.some(f => 
        (f.pageId && f.pageId === file.pageId) || 
        (f.driveId && f.driveId === file.driveId) || 
        (f.sourceDocId && f.sourceDocId === file._id)
      );

      if (isAlreadySelected) {
        // Remove only the specific file
        setFiles(prevFiles => prevFiles.filter(f => 
          !(
            (f.pageId === file.pageId && file.pageId) || 
            (f.driveId === file.driveId && file.driveId) || 
            (f.sourceDocId === file._id && file._id)
          )
        ));
        setFileProgress(prev => {
          const newProgress = { ...prev };
          delete newProgress[file.name];
          return newProgress;
        });
        return;
      }
    
      // Handle Notion pages/databases
      if (file.pageId) {
        const notionFile = {
          name: file.name,
          pageId: file.pageId,
          type: file.type,
          path: file.path,
          isNotionFile: true
        };
        setFileProgress(prev => ({
          ...prev,
          [file.name]: 0
        }));
        
        setFiles(prev => {
          if (prev.some(f => f.pageId === file.pageId)) {
            console.log('File already added:', file.name);
            return prev;
          }
          return [...prev, notionFile];
        });
      } else if (file.driveId) {
        if (file.type === 'folder') {
          const getAllNestedFiles = (folder) => {
            const files = [];
            const processItem = (item) => {
              if (item.type !== 'folder') {
                files.push({
                  name: item.name,
                  driveId: item.driveId,
                  type: item.type,
                  path: item.path,
                  isGoogleDriveFile: true
                });
              }
              if (item.children && item.children.length > 0) {
                item.children.forEach(processItem);
              }
            };
            processItem(folder);
            return files;
          };

          const nestedFiles = getAllNestedFiles(file);
          
          nestedFiles.forEach(nestedFile => {
            setFileProgress(prev => ({
              ...prev,
              [nestedFile.name]: 0
            }));
            
            setFiles(prev => {
              if (prev.some(f => f.driveId === nestedFile.driveId)) {
                console.log('File already added:', nestedFile.name);
                return prev;
              }
              return [...prev, nestedFile];
            });
          });
        } else {
          const driveFile = {
            name: file.name,
            driveId: file.driveId,
            type: file.type,
            path: file.path,
            isGoogleDriveFile: true
          };
          setFileProgress(prev => ({
            ...prev,
            [file.name]: 0
          }));
          
          setFiles(prev => {
            if (prev.some(f => f.driveId === file.driveId)) {
              console.log('File already added:', file.name);
              return prev;
            }
            return [...prev, driveFile];
          });

        }
      } else {

        const localFile = {
          name: file.name,
          sourceDocId: file._id,
          isExistingDocument: true
        };
        
        setFileProgress(prev => ({
          ...prev,
          [file.name]: 0
        }));
        
        setFiles(prev => {
          if (prev.some(f => f.sourceDocId === file._id)) {
            console.log('File already added:', file.name);
            return prev;
          }
          return [...prev, localFile];
        });

        setFileProgress(prev => ({
          ...prev,
          [file.name]: 100
        }));
      }
    } catch (error) {
      console.error('Error adding file:', error);
    }
  };

  const handleRemoveFile = (fileToRemove, index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setFileProgress(prev => {
      const newProgress = { ...prev };
      delete newProgress[fileToRemove.name];
      return newProgress;
    });
  };

  const fetchAgentDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents/${agentId}/details`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const { name, description, miniDescription, aiModel, icon, tools, mood } = response.data;
      setName(name);
      setDescription(description);
      setMiniDescription(miniDescription);
      setAiModel(aiModel);
      setSelectedIcon(icon);
      setTools(tools || { webSearch: false });
      setMood(mood);
    } catch (error) {
      console.error('Error fetching agent details:', error);
    }
  };


  const fetchExistingFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/agents/${agentId}/knowledge`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );

      const groupedFiles = response.data.reduce((acc, file) => {
        if (!acc[file.source]) {
          acc[file.source] = {
            source: file.source,
            firstId: file.id,
            count: 1
          };
        } else {
          acc[file.source].count += 1;
        }
        return acc;
      }, {});
      setExistingFiles(Object.values(groupedFiles));
    } catch (error) {
      console.error('Error fetching existing files:', error);
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const initialProgress = {};
    droppedFiles.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setFileProgress(prev => ({...prev, ...initialProgress}));
    setFiles([...files, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const initialProgress = {};
    selectedFiles.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setFileProgress(prev => ({...prev, ...initialProgress}));
    setFiles([...files, ...selectedFiles]);
  };

  const handleDeleteFile = async (source) => {
    try {
      const encodedSource = escape(source);
      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/agents/${agentId}/knowledge/${source}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );
      fetchExistingFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const updateFileProgress = async (file) => {
    setFileProgress(prev => ({
      ...prev,
      [file.name]: 0
    }));

    const formData = new FormData();
    formData.append('files', file);
    formData.append('topics', `Topic ${file.name}`);

    const updateProgressInterval = setInterval(() => {
      setFileProgress(prev => {
        const currentProgress = prev[file.name] || 0;
        if (currentProgress < 90) {
          return {
            ...prev,
            [file.name]: currentProgress + 1
          };
        }
        return prev;
      });
    }, 100);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/${agentId}/add-knowledge`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      clearInterval(updateProgressInterval);
      setFileProgress(prev => ({
        ...prev,
        [file.name]: 100
      }));

      return response;
    } catch (error) {
      clearInterval(updateProgressInterval);
      setFileProgress(prev => ({
        ...prev,
        [file.name]: -1
      }));
      throw error;
    }
  };

  const handleUploadFiles = async () => {
    setIsUploading(true);
    const totalFiles = files.length;
    let completedFiles = 0;

    try {
      for (const file of files) {
        try {
          if (file.isExistingDocument) {
            await axios.post(
              `${process.env.REACT_APP_BACKEND}/api/agents/${agentId}/copy-knowledge`,
              { sourceDocId: file.sourceDocId },
              {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json'
                }
              }
            );
            completedFiles++;
          } else if (file.isGoogleDriveFile) {
              setFileProgress(prev => ({
                ...prev,
                [file.name]: 0
              }));

              const progressInterval = setInterval(() => {
                setFileProgress(prev => {
                  const currentProgress = prev[file.name] || 0;
                  if (currentProgress < 90) {
                    return {
                      ...prev,
                      [file.name]: currentProgress + 2
                    };
                  }
                  return prev;
                });
              }, 100);

              try {
                const result = await axios.post(
                  `${process.env.REACT_APP_BACKEND}/api/google/agent-knowledge/${agentId}`,
                  { 
                    fileId: file.driveId,
                    name: file.name
                  },
                  {
                    headers: {
                      'Authorization': `Bearer ${localStorage.getItem('token')}`,
                      'Content-Type': 'application/json'
                    }
                  }
                );

                clearInterval(progressInterval);
                setFileProgress(prev => ({
                  ...prev,
                  [file.name]: 100
                }));

                if (!result.data.addedKnowledge || result.data.addedKnowledge.length === 0) {
                  throw new Error('No knowledge chunks were created');
                }

                completedFiles++;
              } catch (error) {

                clearInterval(progressInterval);
                setFileProgress(prev => ({
                  ...prev,
                  [file.name]: -1
                }));
                throw error;
              }
          } else if (file.isNotionFile) {
              const progressInterval = setInterval(() => {
                setFileProgress(prev => {
                  const currentProgress = prev[file.name] || 0;
                  if (currentProgress < 80) {
                    return {
                      ...prev,
                      [file.name]: currentProgress + 2
                    };
                  }
                  return prev;
                });
              }, 100);

              await axios.post(
                `${process.env.REACT_APP_BACKEND}/api/notion/agent-knowledge/${agentId}`,
                { 
                  pageId: file.pageId,
                  name: file.name,
                  type: file.type
                },
                {
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                  }
                }
              );
              clearInterval(progressInterval);
              setFileProgress(prev => ({
                ...prev,
                [file.name]: 100
              }));
              completedFiles++;
          } else {
            await updateFileProgress(file);
            completedFiles++;
          }
          
          setOverallProgress((completedFiles / totalFiles) * 100);
          setFileProgress(prev => ({
            ...prev,
            [file.name]: 100
          }));
        } catch (error) {
          console.error(`Failed to process file ${file.name}:`, error);
          setFileProgress(prev => ({
            ...prev,
            [file.name]: -1 
          }));
          toast.error(`Failed to process file ${file.name}.`, {
            theme: "colored",
          });
        }
      }

      setOverallProgress(100);
      setFiles([]);
      fetchExistingFiles();
      if (completedFiles > 0) {
        toast('Files processed successfully', {
          theme: "light",
        });
      }

    } catch (error) {
      console.error('Error uploading files:', error);
      toast.error('Error uploading files', {
        theme: "colored",
      });
    } finally {
      setIsUploading(false);
      setOverallProgress(0);
    }
  };

  const fetchAvailableModels = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chatmodels`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const filteredModels = response.data.filter(model => model.name !== 'Multi Modal');
      setAvailableModels(filteredModels);
    } catch (error) {
      console.error('Error fetching available models:', error);
    }
  };

  const getModelIcon = (modelName) => {
    const modelsIcons = {
      'GPT4': '/images/gpt.png',
      'GPT4o': '/images/gpt.png',
      'GPT4oMini': '/images/gpt.png',
      'Claude3.5': '/images/claude.png',
      'ClaudeSonnet': '/images/claude.png',
      'Claude Opus': '/images/claude.png',
      'Claude Haiku': '/images/claude.png',
      'Mistral AI Large': '/images/mistral.png',
      'Mistral AI Medium': '/images/mistral.png',
      'Mistral AI Small': '/images/mistral.png',
      'Codestral': '/images/mistral.png',
      'Llama3.1': '/images/llama.png',
      'Gemini1.5': '/images/gemini.png',
      'Perplexity': '/images/perplexity.png',
      'Stable Diffusion': '/images/stablediffusion.png',
      'DALL-E 3': '/images/gpt.png',
    };
    
    const normalizedModelName = modelName.replace(/\s+/g, '').toLowerCase();
    const iconPath = Object.entries(modelsIcons).find(([key]) => 
      key.replace(/\s+/g, '').toLowerCase() === normalizedModelName
    );
    
    return iconPath ? iconPath[1] : '/images/default.png';
  };

  const getModelEndpoint = (model) => {
    switch (model.toLowerCase()) {
      case 'gpt 4o':
        return 'gpt4o';
      case 'gpt 4o mini':
        return 'gpt4omini';
      case 'gpt 4':
        return 'gpt4';
      case 'claude 3.5':
        return 'claude3.5';
      case 'claude sonnet':
        return 'claudesonnet';
      case 'claude opus':
        return 'claude-opus';
      case 'claude haiku':
        return 'claude-haiku';
      case 'mistral ai large':
        return 'mistral-large';
      case 'mistral ai medium':
        return 'mistral-medium';
      case 'mistral ai small':
        return 'mistral-small';
      case 'codestral':
        return 'codestral';
      case 'llama 3.1':
        return 'llama3.1';
      case 'gemini 2':
        return 'gemini1.5';
      case 'perplexity':
        return 'perplexity';
      case 'dall-e3':
      case 'dall-e 3':
        return 'dall-e3';
      case 'stable diffusion':
      case 'stablediffusion':
        return 'stablediffusion';
      default:
        return model.toLowerCase().replace(' ', '');
    }
  };

  const handleImproveDescription = async () => {
    setIsImprovingDescription(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/generate-description`,
        { userDescription: description },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setDescription(response.data.generatedDescription);
    } catch (error) {
      console.error('Error improving description:', error);
      toast.error('Error improving prompt. Please try again.', {
        theme: "colored",
      });
    } finally {
      setIsImprovingDescription(false);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/agents/${agentId}/update`, 
        {
          name,
          description,
          miniDescription,
          aiModel: getModelEndpoint(aiModel),
          icon: selectedIcon,
          tools,
          mood: mood / 100,
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );

      navigate('/agents');
      toast(`Successfully updated ${name}!`, {
        theme: "light",
      });
    } catch (error) {
      console.error('Error updating agent:', error);
      toast.error('Failed to update the agent.', {
        theme: "colored",
      });
      setIsUpdating(false);
    }
  };


return (
  <div className="edit-agent-page" ref={topRef}>
    <button onClick={() => navigate('/agents')} className="back-button-edit">
      <FaArrowLeftLong size={20} />
    </button>
    <div className="edit-agent-content">
      <Tabs.Root value={currentTab} onValueChange={setCurrentTab}>
        <Tabs.List className="tab-list">
          <Tabs.Trigger value="identity" className="tab-trigger">
            Identity
          </Tabs.Trigger>
          <Tabs.Trigger value="mission" className="tab-trigger">
            Mission
          </Tabs.Trigger>
          <Tabs.Trigger value="knowledge" className="tab-trigger">
            Knowledge & Tools
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="identity" className="tab-content">
          <div className="form-group">
            <label>Agent Icon</label>
            <CategorizedIconDropdown
              selectedIcon={selectedIcon}
              onIconSelect={(icon) => {
                setSelectedIcon(icon);
                setIsIconDropdownOpen(false);
              }}
              isOpen={isIconDropdownOpen}
              onOpenChange={setIsIconDropdownOpen}
              availableIcons={availableIcons}
            />
          </div>
          
          <div className="form-group">
            <label>Agent Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Agent name"
            />
          </div>

          <div className="form-group">
            <label>Mini Description</label>
            <input
              type="text"
              value={miniDescription}
              onChange={(e) => setMiniDescription(e.target.value)}
              placeholder="Describe the agent in one sentence"
            />
          </div>

          <div className="form-group">
            <label>AI Model</label>
            <DropdownMenu.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
              <DropdownMenu.Trigger className="model-dropdown-trigger">
                {aiModel || "Select Model"} <FiChevronUp />
              </DropdownMenu.Trigger>

              <DropdownMenu.Portal>
                <DropdownMenu.Content className="model-dropdown-content" side="top" sideOffset={5}>
                  {availableModels.map((model) => (
                    <DropdownMenu.Item
                      key={model._id}
                      className="model-item"
                      onSelect={() => {
                        setAiModel(model.name);
                        setIsDropdownOpen(false);
                      }}
                    >
                      <div className="model-info">
                        <div className="model-name">
                          <img src={getModelIcon(model.name)} alt={model.name} className="model-icon" />
                          {model.name}
                        </div>
                        <div className="model-description">{model.miniDescription}</div>
                      </div>
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>

          <div className="form-actions">
            <button className="update-button" onClick={() => setCurrentTab('mission')}>
              Next →
            </button>
          </div>
        </Tabs.Content>

        <Tabs.Content value="mission" className="tab-content">
            <h2 className="mission-title">Mission Statement</h2>
            <p className="mission-description">
              Here you can give your agent a <b><strong>prompt</strong></b> to have the best in class instructions to do its tasks.
            </p>
            <button 
              className='how-to-prompt-button' 
              onClick={() => window.open('https://calk-ai.notion.site/how-to-prompt-101?pvs=74', '_blank')}
            >
              Check our Class on how to prompt ↗
            </button>
            <div className="mission-content-wrapper">
              {/* Left side */}

              <div className="mission-textarea-container">
                <div className="textarea-wrapper">
                  <textarea
                    ref={textareaRef}
                    value={description}
                    onChange={handleTextareaChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Write your best prompt or just write what you want to with this agent and click the 'Enhance Prompt' button for some help."
                    className="mission-textarea"
                  />
                  {showSectionsDropdown && (
                    <div 
                      className="sections-dropdown" 
                      ref={dropdownRef}
                    >
                      <div className="sections-header">Add sections to your prompt</div>
                      {promptSections.map((section, index) => {
                        const Icon = section.icon;
                        return (
                          <HoverCard.Root openDelay={20} closeDelay={20}>
                            <HoverCard.Trigger asChild>
                              <div
                                key={section.name}
                                className={`agent-section-item ${index === selectedIndex ? 'selected' : ''}`}
                                onClick={() => insertSection(section.name)}
                                onMouseEnter={() => setSelectedIndex(index)}
                              >
                                <div className="agent-section-icon">
                                  <Icon />
                                </div>
                                <div className="agent-section-info">
                                  <div className="agent-section-name">{section.name}</div>
                                  <div className="agent-section-description">{section.description}</div>
                                </div>
                              </div>
                            </HoverCard.Trigger>
                            <HoverCard.Portal>
                              <HoverCard.Content sideOffset={5} className="agent-radix-hover-content" side="right">
                                {section.info}
                              </HoverCard.Content>
                            </HoverCard.Portal>
                          </HoverCard.Root>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="form-actions-mission">
                  <button className="back-button-mission" disabled={isImprovingDescription} onClick={() => setCurrentTab('identity')}>
                    ← Back
                  </button>
                  <button 
                    className="add-section-button-mission" 
                    disabled={isImprovingDescription} 
                    onClick={handleAddSection}
                  >
                    + Add Section #
                  </button>
                  <button 
                    className={`enhance-button ${isImprovingDescription ? 'disabled' : ''}`} 
                    onClick={handleImproveDescription}
                    disabled={isImprovingDescription}
                  >
                    {isImprovingDescription ? 'Enhancing...' : 'Enhance prompt ✨'}
                  </button>
                </div>
              </div>

              {/*<div className="mission-textarea-container">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Write your best prompt or just write what you want to with this agent and click the 'Enhance Prompt' button for some help."
                  className="mission-textarea"
                />
                <div className="form-actions-mission">
                  <button className="back-button-mission" disabled={isEnhancing} onClick={() => setCurrentTab('identity')}>
                    ← Back
                  </button>
                  <button 
                    className={`enhance-button ${isImprovingDescription ? 'disabled' : ''}`} 
                    onClick={handleEnhancePrompt}
                    disabled={isImprovingDescription}
                  >
                    {isImprovingDescription ? 'Enhancing...' : 'Enhance prompt ✨'}
                  </button>
                </div>
              </div>*/}

              {/* Right side */}
              <div className="mission-right-section">
                <CreateAgentChat 
                  missionPrompt={description} 
                  model={aiModel}
                  onModelChange={(newModel) => setAiModel(newModel)}
                  agentName={name}
                  agentIcon={selectedIcon}
                  temp={mood}
                  onTemperatureChange={(newTemp) => setMood(newTemp)}
                />
                <button className="next-button-mission" disabled={isImprovingDescription} onClick={() => setCurrentTab('knowledge')}>
                  Next →
                </button>
              </div>
            </div>

          </Tabs.Content>

          <Tabs.Content value="knowledge" className="tab-content">

            <h2>Tools</h2>

            <div className="url-input-section">
              <input
                type="text" 
                placeholder="Enter URL to scrape and add to the knowledge base (e.g. https://example.com)" 
                value={urlInput}
                onChange={(e) => setUrlInput(e.target.value)}
                disabled={isUploading || isScrapingUrl}
                className="url-text-input"
              />
              <Button 
                onClick={handleUrlAdd}
                disabled={isUploading || isScrapingUrl || !urlInput.trim()}
                variant="solid"
                className="url-button-section"
              >
                {isScrapingUrl ? 'Scraping...' : 'Add URL'}
              </Button>
            </div>

            <div className="tools-section">
              <div className="tools-container">
                <div className="tool-item">
                  <div className="tool-info">
                    <div className="tool-icon">
                      <img 
                        src="/images/all_icons/symbols_globe with meridians.png" 
                        alt="Web Search"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </div>
                    <div className="tool-details">
                      <span className="tool-name">Web Search</span>
                      <span className="tool-description">Enable real-time web search capabilities</span>
                    </div>
                  </div>
                  <Switch
                    checked={tools.webSearch}
                    onCheckedChange={(checked) => setTools(prev => ({ ...prev, webSearch: checked }))}
                    className="toggle-switch"
                  >
                    <Switch.Thumb className="toggle-switch-thumb" />
                  </Switch>
                </div>
              </div>
            </div>

            {/* Existing Files Section */}
            <div className="existing-files-section">
              <h3 className="section-subtitle">Current Knowledge Base</h3>
              {existingFiles && existingFiles.length > 0 ? (
                existingFiles.map((file) => (
                  <div key={file.source} className="file-group">
                    <div className="file-group-header">
                      <div className="file-group-name">
                        <span className="text-sm font-medium">{file.source}</span>
                      </div>
                      <AlertDialog.Root>
                        <AlertDialog.Trigger className="dropdown-item-trigger-document">
                          <IoTrashOutline />&nbsp;
                          Delete File
                        </AlertDialog.Trigger>
                        
                        <AlertDialog.Portal>
                          <AlertDialog.Overlay className="alert-overlay" />
                          <AlertDialog.Content className="alert-content">
                            <AlertDialog.Title className="alert-title">
                              Delete File
                            </AlertDialog.Title>
                            
                            <AlertDialog.Description className="alert-description">
                              Are you sure you want to delete this document? This action cannot be undone.
                            </AlertDialog.Description>
                            
                            <div className="alert-buttons">
                              <AlertDialog.Cancel asChild>
                                <button className="alert-button cancel">
                                  Cancel
                                </button>
                              </AlertDialog.Cancel>
                              
                              <AlertDialog.Action asChild>
                                <button
                                  onClick={() => handleDeleteFile(file.source)}
                                  className="alert-button delete"
                                  title={`Delete ${file.source}`}
                                >
                                  Delete
                                </button>
                              </AlertDialog.Action>
                            </div>
                          </AlertDialog.Content>
                        </AlertDialog.Portal>
                      </AlertDialog.Root>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state">
                  <p>No files uploaded yet</p>
                </div>
              )}
            </div>

            {/* Upload Section with split layout */}
            <div className="upload-section">
              <h3 className="section-subtitle">Add New Knowledge</h3>
              <div className="knowledge-flex-container">
                {/* Left side - File upload zone */}
                <div className="upload-section">
                  <div 
                    className={`file-upload-zone ${isUploading ? 'uploading' : ''}`}
                    onDrop={handleFileDrop}
                    onDragOver={handleDragOver}
                  >
                    <input
                      type="file"
                      multiple
                      onChange={handleFileSelect}
                      className="file-input"
                      id="file-upload"
                    />
                    <Upload className="upload-icon" />
                    <span className="upload-text">
                      Drop your files here or click to browse
                    </span>
                  </div>
                </div>

                {/* Right side - Existing documents */}
                <FileBrowser
                  existingDocuments={existingDocuments}
                  savedDriveRefs={savedDriveRefs}
                  savedNotionRefs={savedNotionRefs}
                  selectedFiles={files}
                  onFileSelect={handleAddExistingFile}
                />
              </div>

              {/* Progress Section */}
              {files.length > 0 && (
                <div className="files-list">
                  {files.map((file, index) => (
                    <div 
                      key={index}
                      className={`file-item ${
                        isUploading ? 'shadow-lg' : ''
                      } ${
                        fileProgress[file.name] === 100 ? 'opacity-50' : ''
                      }`}
                    >
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center">
                          <Button 
                            onClick={() => handleRemoveFile(file, index)}
                            className="remove-file-button"
                            disabled={isUploading}
                            variant="ghost"
                            color="red" 
                          >
                            <Trash2 />
                          </Button>
                          <span className="text-sm font-medium">{file.name}</span>
                        </div>
                        <span className="text-xs text-slate-500">
                          {file.size ? `${(file.size / (1024 * 1024)).toFixed(2)} MB` : ''}
                        </span>
                      </div>
                      
                      <div className="progress-bar-container">
                        <div 
                          className="progress-bar-fill"
                          style={{ 
                            width: `${fileProgress[file.name] || 0}%`,
                            transition: 'width 0.5s ease-in-out'
                          }}
                        />
                      </div>
                      <div className="text-xs text-slate-500 mt-1">
                        {Math.round(fileProgress[file.name] || 0)}%
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Actions Bar */}
            <div className="actions-bar">
              {files.length > 0 && (
                <button 
                  className={`upload-button ${isUploading ? 'uploading' : ''}`}
                  onClick={handleUploadFiles}
                  disabled={isUploading}
                >
                  {isUploading ? 'Uploading...' : 'Upload Files'}
                </button>
              )}
              <button className="back-button-mission" disabled={isImprovingDescription} onClick={() => setCurrentTab('mission')}>
              ← Back
              </button>
              <button 
                className="update-button"
                onClick={handleSubmit}
                disabled={isUpdating}
              >
                {isUpdating ? 'Updating...' : 'Update Agent →'}
              </button>
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  );
};

export default EditAgentPage;