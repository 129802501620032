import React, { useState, useEffect } from 'react';
import { Table } from '@radix-ui/themes';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { FaPencilAlt } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/ChatManagement.css';

const ChatManagement = () => {
  const [chats, setChats] = useState([]);
  const [selectedChats, setSelectedChats] = useState([]);
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      fetchChats();
    }
  }, [isAuthenticated]);

  const fetchChats = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chat/all`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      setChats(response.data);
    } catch (error) {
      console.error('Error fetching chats:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChat = (chatId) => {
    setSelectedChats(prev => 
      prev.includes(chatId) 
        ? prev.filter(id => id !== chatId)
        : [...prev, chatId]
    );
  };

  const handleOpenChat = (chat) => {
    const route = chat.isAgentChat 
      ? `/home/agents/${chat._id}`
      : `/home/chat/${chat._id}`;
    navigate(route);
  };

  const handleBulkDelete = async () => {
      try {
        const token = localStorage.getItem('token');
        const agentChatIds = selectedChats.filter(chatId => 
          chats.find(chat => chat._id === chatId)?.isAgentChat
        );
        const regularChatIds = selectedChats.filter(chatId => 
          !chats.find(chat => chat._id === chatId)?.isAgentChat
        );

        if (agentChatIds.length > 0) {
          await axios.post(`${process.env.REACT_APP_BACKEND}/api/agents/delete-bulk`, 
            { chatIds: agentChatIds },
            { headers: { 'Authorization': `Bearer ${token}` }}
          );
        }

        if (regularChatIds.length > 0) {
          await axios.post(`${process.env.REACT_APP_BACKEND}/api/chat/delete-bulk`, 
            { chatIds: regularChatIds },
            { headers: { 'Authorization': `Bearer ${token}` }}
          );
        }

        fetchChats();
        setSelectedChats([]);
      } catch (error) {
        console.error('Error deleting chats:', error);
      }
  };

  const handleEditTitle = async (chatId) => {
    try {
      const token = localStorage.getItem('token');
      const chat = chats.find(c => c._id === chatId);
      const endpoint = chat.isAgentChat
        ? `${process.env.REACT_APP_BACKEND}/api/agents/${chat.agentId}/chat/${chatId}/title`
        : `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/title`;

      await axios.put(endpoint, 
        { title: editingTitle },
        { headers: { 'Authorization': `Bearer ${token}` }}
      );

      setEditingChatId(null);
      setEditingTitle('');
      fetchChats();
    } catch (error) {
      console.error('Error updating chat title:', error);
    }
  };

  return (
    <div className="chat-management">
      <div className="chat-management-header">
        <h1>Manage Conversations</h1>
        {selectedChats.length > 0 && (
          <button 
            className="bulk-delete-button"
            onClick={handleBulkDelete}
          >
            Delete Selected ({selectedChats.length})
          </button>
        )}
      </div>

      <div className="table-container">
        <div className="table-header">
          <Table.Root variant="surface">
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Messages</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Files</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Date</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Select</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
          </Table.Root>
        </div>

        <ScrollArea className="table-scroll-area">
          {loading ? (
            <div className="loading-spinner">
              <div className="spinner" />
            </div>
          ) : (
            <Table.Root variant="surface">
              <Table.Body>
                {chats.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan={4} style={{ textAlign: 'center' }}>
                      No conversations found
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  chats.map(chat => (
                    <Table.Row 
                      key={chat._id}
                      className="chat-management-open-chat"
                      onClick={() => handleOpenChat(chat)}
                    >
                      <Table.Cell>
                        {editingChatId === chat._id ? (
                          <div 
                            className="chat-management-edit-title-container"
                            onClick={(event) => event.stopPropagation()}
                          >
                            <input
                              type="text"
                              value={editingTitle}
                              onChange={(e) => setEditingTitle(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') handleEditTitle(chat._id);
                              }}
                              className="chat-management-edit-title-input"
                            />
                            <button 
                              onClick={() => handleEditTitle(chat._id)}
                              className="chat-management-save-title-button"
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div className="chat-management-title-container">
                            <FaPencilAlt
                              className="chat-management-edit-icon"
                              onClick={(event) => {
                                event.stopPropagation();
                                setEditingChatId(chat._id);
                                setEditingTitle(chat.title);
                              }}
                            />
                            {chat.title}
                          </div>
                        )}
                      </Table.Cell>
                      <Table.Cell>{chat.messageCount || 0}</Table.Cell>
                      <Table.Cell>{chat.attachmentCount || 0}</Table.Cell>
                      <Table.Cell>
                        {new Date(chat.createdAt).toLocaleDateString()}
                      </Table.Cell>
                      <Table.Cell>
                        <input
                          type="checkbox"
                          checked={selectedChats.includes(chat._id)}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) => {
                            event.stopPropagation();
                            handleSelectChat(chat._id);
                          }}
                          className="chat-management-select-checkbox"
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
                )}
              </Table.Body>
            </Table.Root>
          )}
        </ScrollArea>
      </div>
    </div>
  );
};

export default ChatManagement;