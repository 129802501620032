import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Eye, EyeOff } from 'lucide-react';
import '../styles/Login.css';
import { toast, Bounce } from 'react-toastify';

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login, googleLogin } = useAuth();

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn
      });

      window.google.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        { theme: 'outline', size: 'large' }
      );
    };

    if (window.google && window.google.accounts) {
      initializeGoogleSignIn();
    } else {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    }
  }, []);


  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const success = await login(credentials);
    if (success) {
      navigate('/home');
    } else {
      setError('Invalid email or password');
      toast.error('Invalid email of password.', {
        theme: "colored",
      });
    }
  };

  const handleGoogleSignIn = async (response) => {
    try {
      const { credential } = response;

      const result = await googleLogin(credential);

      if (result.success) {
        if (result.isNewUser) {
          navigate('/register-sso', { 
            state: { 
              googleUser: {
                firstName: result.user.firstName,
                lastName: result.user.lastName,
                email: result.user.email,
                password: result.user.password
              }
            } 
          });
        } else {
          navigate('/home');
        }
      } else {
        setError(result.message || 'An error occurred during Google Sign-In');
      }
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
      setError('An unexpected error occurred during Google Sign-In');
    }
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <img src="/white-logo.png" alt="Calk Logo" className="logo" />
        <div className="testimonial">
          <p className="testimonial-quote">
            "The best is yet to come."
          </p>
          <p className="testimonial-author">Calk Team</p>
        </div>
      </div>
      <div className="login-right">
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Welcome back!</h2>
          <p className="login-subtitle">Enter your details below to sign in!</p>

          <div className="input-group">
            <label htmlFor="email">Work Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              placeholder="elon@spacex.com"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="password-toggle"
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>

          <button type="submit" className="submit-button">Log in</button>

          <div className="separator">
            <span>OR LOG IN WITH</span>
          </div>

          <div id="google-signin-button"></div>
          
          <p className="form-link">
            No account yet? <Link to="/register">Create an account for free</Link>
          </p>
          
          <div className="policies">
            <a href="https://www.calk-ai.com/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
            <a href="https://www.calk-ai.com/terms-of-use" rel="noopener noreferrer" target="_blank">Terms of Service</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;




