// MessageHeader.jsx
import React from 'react';
import FormatMessage from './FormatMessage';
import { Tooltip } from '@radix-ui/themes';
import { FileText, Image, Globe } from 'lucide-react';

const Sources = ({ sources }) => {
  // if (!sources?.length) return null;
  if (!sources || sources.length === 0) return null;
  
  const fileNames = sources.filter(source => (source.includes('.') || source.includes('-')) && !source.includes('http')).map(source => ({
   type: 'file',
   name: source
  }));
  
  const urls = sources.filter(source => source.includes('http')).map(source => ({
    type: 'url',
    name: new URL(source).hostname,
    full: source
  }));
  
  const allSources = [...fileNames, ...urls];
  if (!allSources.length) return null;

  return (
    <div className="message-sources">
      <Tooltip content={
        <div className="sources-tooltip">
          <div className="sources-tooltip-content ">
            {allSources.map((source, i) => (
              <div key={i} className="source-item">
                {source.type === 'file' ? (
                  <>
                    <FileText size={15} className="text-blue-600" />
                    <span>{source.name}</span>
                  </>
                ) : (
                  <>
                    <Globe size={15} className="text-blue-600" />
                    <span>{source.name}</span>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      } side="top" align="start" sideOffset={5} className="rt-sources-tooltip-content">
        <div className="sources-trigger">
          {/*<span>Sources</span>*/}
          <span className="sources-icons">
            {allSources.map((source, i) => (
              <div key={i} className="source-icon">
                {source.type === 'file' ? (
                  <FileText size={15} className="text-blue-600" />
                ) : (
                  <Globe size={15} className="text-blue-600" />
                )}
              </div>
            ))}
          </span>
        </div>
      </Tooltip>
    </div>
  );
};//

export const MessageHeader = ({ role, userFirstName, userLastName, message, getMessageIcon, getEntityNameForMessage }) => {
  if (role === 'assistant') {
    return (
      <>
        <img 
          src={getMessageIcon(message)} 
          alt={getEntityNameForMessage(message)} 
        />
        <span>{getEntityNameForMessage(message)}</span>
      </>
    );
  }

  return (
    <>
      <div className="user-initials-circle">
        {userFirstName?.charAt(0)}{userLastName?.charAt(0)}
      </div>
      <span>{userFirstName}</span>
    </>
  );
};

// MessageActions.jsx
export const MessageActions = ({ message, handleFeedback, copyToClipboard }) => {
  return (
    <div className="message-actions">
      <button 
        className={`feedback-button ${message.feedback === 'positive' ? 'active' : ''}`}
        onClick={() => handleFeedback(message._id, true)}
        title="Thumbs up"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill={message.feedback === 'positive' ? 'rgba(0, 89, 255, 0.2)' : 'currentColor'}>
          <path d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"/>
        </svg>
      </button>
      <button 
        className={`feedback-button ${message.feedback === 'negative' ? 'active' : ''}`}
        onClick={() => handleFeedback(message._id, false)}
        title="Thumbs down"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill={message.feedback === 'negative' ? 'black' : 'currentColor'}>
          <path d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15z"/>
        </svg>
      </button>
      <button 
        className="copy-button" 
        onClick={() => copyToClipboard(message.content)}
        title="Copy message"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
        </svg>
      </button>
    </div>
  );
};

// Message.jsx


export const Message = ({ message, imageData, copyToClipboard, progressState, sources }) => {

  if (message.isImage && 
      (message.aiModel?.toLowerCase() === 'stablediffusion' || 
       message.aiModel?.toLowerCase() === 'dall-e3')) {
    return <div>Generating image...</div>;
  }//

  if (message.isImage && imageData) {
    return (
      <img 
        src={`data:image/png;base64,${imageData}`}
        alt="Generated" 
        className="generated-image"
      />
    );
  }
  
  // Check both attachedFiles and fileContent for attachments
  const hasAttachments = (message.attachedFiles && message.attachedFiles.length > 0) || 
                        (message.fileContent && message.fileContent.length > 0);
  
  return (
    <div>
      <FormatMessage content={message.content} copyToClipboard={copyToClipboard} progressState={progressState} role={message.role} />
      {message.attachedFiles && message.attachedFiles.length > 0 && (
        <div className="message-attachments">
          {message.attachedFiles.map((file, index) => (
            <Tooltip
              key={index}
              content={file.name}
              className="attachment-tooltip"
            >
              <div>
                {file.type === 'file' ? (
                  <FileText className="attachment-icon" size={16} />
                ) : (
                  <Image className="attachment-icon" size={16} />
                )}
              </div>
            </Tooltip>
          ))}
        </div>
      )}
      {message.role === 'assistant' && message.sources && message.sources.length > 0 && (
        <Sources sources={message.sources} />
      )}
    </div>
  );
};

export default Message;

// MessageContainer.jsx
export const MessageContainer = ({ 
  messageGroup, 
  MessageHeader,
  Message,
  MessageActions,
  role,
  userFirstName,
  userLastName,
  imageData,
  getMessageIcon,
  getEntityNameForMessage,
  handleFeedback,
  copyToClipboard,
  progressState,
  sources
}) => {
  // If we have a group of 2 messages, wrap them in a messages-row
  if (messageGroup.length === 2 && messageGroup[0].role === 'assistant') {
    return (
      <div className="messages-row">
        {messageGroup.map((message, index) => (
          <div key={index} className={`message-container ${message.role}`}>
            <div className="message-header">
              <MessageHeader 
                role={message.role}
                userFirstName={userFirstName}
                userLastName={userLastName}
                message={message}
                getMessageIcon={getMessageIcon}
                getEntityNameForMessage={getEntityNameForMessage}
              />
            </div>
            <div className="message">
              <Message 
                message={message}
                imageData={imageData}
                copyToClipboard={copyToClipboard}
                progressState={progressState}
                sources={sources} 
              />
              {message.role === 'assistant' && (
                <MessageActions 
                  message={message}
                  handleFeedback={handleFeedback}
                  copyToClipboard={copyToClipboard}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Original single message rendering
  return (
    <div className={`message-container ${messageGroup[0].role}`}>
      <div className="message-header">
        <MessageHeader 
          role={messageGroup[0].role}
          userFirstName={userFirstName}
          userLastName={userLastName}
          message={messageGroup[0]}
          getMessageIcon={getMessageIcon}
          getEntityNameForMessage={getEntityNameForMessage}
        />
      </div>
      <div className="message">
        <Message 
          message={messageGroup[0]}
          imageData={imageData}
          copyToClipboard={copyToClipboard}
          progressState={progressState}
          sources={sources} 
        />
        {messageGroup[0].role === 'assistant' && (
          <MessageActions 
            message={messageGroup[0]}
            handleFeedback={handleFeedback}
            copyToClipboard={copyToClipboard}
          />
        )}
      </div>
    </div>
  );
};
//   return (
//     <div className={`message-container ${messageGroup[0].role}`}>
//       <div className="message-header">
//         <MessageHeader 
//           role={messageGroup[0].role}
//           userFirstName={userFirstName}
//           userLastName={userLastName}
//           message={messageGroup[0]}
//           getMessageIcon={getMessageIcon}
//           getEntityNameForMessage={getEntityNameForMessage}
//         />
//       </div>
//       <div className="message">
//         <Message 
//           message={messageGroup[0]}
//           imageData={imageData}
//           copyToClipboard={copyToClipboard}
//           progressState={progressState}
//           sources={sources} 
//         />
//         {messageGroup[0].role === 'assistant' && (
//           <MessageActions 
//             message={messageGroup[0]}
//             handleFeedback={handleFeedback}
//             copyToClipboard={copyToClipboard}
//           />
//         )}
//       </div>
//     </div>
//   );
// };