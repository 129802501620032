import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Eye, EyeOff, Check } from 'lucide-react';
import axios from 'axios';
import '../styles/Login.css';
import { toast, Bounce } from 'react-toastify';

const RegisterSSO = () => {
  const [step, setStep] = useState(2); // Start from step 2
  const [credentials, setCredentials] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  useEffect(() => {
    if (location.state?.googleUser) {
      setCredentials(prevState => ({
        ...prevState,
        firstName: location.state.googleUser.firstName,
        lastName: location.state.googleUser.lastName,
        email: location.state.googleUser.email,
        password: location.state.googleUser.password,
      }));
    } else {
      navigate('/register');
    }
  }, [location.state, navigate]);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    const success = await login({ email: credentials.email, password: credentials.password });
    if (success) {
      navigate('/home');
      toast.success('Login successful!', {
        theme: "colored",
      });
    } else {
      setError('Unable to log in. Please try logging in manually.');
      toast.error('Login Error', {
        theme: "colored",
      });
    }
  };

  return (
    <div className="login-container">
      <div className="final-step">
        <h2>All set: AIs know you now</h2>
        <div className="checkmark">✓</div>
        <button onClick={handleLogin} className="primary-button">
          Go to Calk
        </button>
      </div>
    </div>
  );
};
export default RegisterSSO;
