import React, { useState, useEffect } from 'react';
import { AlertDialog, Flex, Text } from '@radix-ui/themes';
import { FaTimes, FaFileAlt, FaSearch } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import '../styles/NotionPopup.css';

const NOTION_CACHE_KEY = 'notion_files_cache';
const NOTION_CACHE_DURATION = 30 * 60 * 1000; // 15 minutes
const AUTO_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

const PageList = ({ pages, selectedPages, onPageSelect }) => {
  const [expandedPages, setExpandedPages] = useState({});
  const [organizedPages, setOrganizedPages] = useState([]);

  useEffect(() => {
    // console.log('Incoming pages:', pages);

    // Find database entries
    const databases = pages.filter(page => page.type === 'database');
    
    // Find root pages that aren't in any database
    const databaseChildrenIds = new Set(
      databases.flatMap(db => db.children?.map(child => child.id) || [])
    );
    
    const rootPages = pages.filter(page => 
      page.type === 'page' && 
      !page.parentId &&
      !databaseChildrenIds.has(page.id)
    );

    // Combine databases and standalone root pages
    const organized = [...databases, ...rootPages];
    
    // console.log('Organized pages:', organized);
    setOrganizedPages(organized);
  }, [pages]);

  const toggleExpand = (pageId, e) => {
    e.stopPropagation();
    setExpandedPages(prev => ({
      ...prev,
      [pageId]: !prev[pageId]
    }));
  };

  const renderPage = (page, depth = 0) => {
    const isExpanded = expandedPages[page.id];
    const hasChildren = page.children?.length > 0;

    return (
      <div key={page.id}>
        <div 
          className={`notion-page-item ${selectedPages.some(p => p.id === page.id) ? 'selected' : ''}`}
          onClick={() => onPageSelect(page)}
          style={{ paddingLeft: `${20 + (depth * 24)}px` }}
        >
          <div className="notion-page-content">
            {hasChildren && (
              <button 
                className="notion-expand-button"
                onClick={(e) => toggleExpand(page.id, e)}
              >
                <IoIosArrowForward 
                  className={`notion-arrow ${isExpanded ? 'expanded' : ''}`}
                />
              </button>
            )}
            <div className="notion-page-icon">
              {page.type === 'database' ? '🗄️' : page.icon?.emoji || '📄'}
            </div>
            <Text className="notion-page-title">
              {page.title} {page.type === 'database' && ' (Database)'}
            </Text>
          </div>
          {selectedPages.some(p => p.id === page.id) && (
            <div className="notion-check">✓</div>
          )}
        </div>
        
        {hasChildren && isExpanded && (
          <div className="notion-nested-pages">
            {page.children.map(child => renderPage({
              ...child,
              // Ensure children have correct properties
              type: 'page',
              hasChildren: false,
              children: []
            }, depth + 1))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="notion-page-list">
      {organizedPages.map(page => renderPage(page))}
    </div>
  );
};

const NotionPopup = ({ 
  isOpen, 
  onClose, 
  pages: propPages, 
  selectedPages, 
  onPageSelect, 
  onContinue,
  isLoading 
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [cachedPages, setCachedPages] = useState([]);
  const [isLoadingPages, setIsLoadingPages] = useState(false);

  useEffect(() => {
    const fetchPages = async () => {
      const cached = localStorage.getItem(NOTION_CACHE_KEY);
      if (cached) {
        const { data, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp < NOTION_CACHE_DURATION) {
          setCachedPages(data);
          return;
        }
      }

      setIsLoadingPages(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/notion/pages`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        localStorage.setItem(NOTION_CACHE_KEY, JSON.stringify({
          data,
          timestamp: Date.now()
        }));
        setCachedPages(data);
      } catch (error) {
        console.error('Error prefetching notion pages:', error);
      } finally {
        setIsLoadingPages(false);
      }
    };

    const intervalId = setInterval(() => {
      if (isOpen) {
        localStorage.removeItem(NOTION_CACHE_KEY);
        fetchPages();
      }
    }, AUTO_REFRESH_INTERVAL);

    if (isOpen) {
      fetchPages();
    }

    return () => clearInterval(intervalId);
  }, [isOpen]);

  const searchPages = (pages, term) => {
    if (!term) return pages;
    const matches = [];
    
    const searchInPages = (pagesArray) => {
      pagesArray.forEach(page => {
        if (page.title.toLowerCase().includes(term.toLowerCase())) {
          matches.push(page);
        }
        if (page.children && page.children.length > 0) {
          const childMatches = page.children.filter(child =>
            child.title.toLowerCase().includes(term.toLowerCase())
          );
          matches.push(...childMatches);
        }
      });
    };

    searchInPages(pages);
    return matches;
  };

  const displayPages = cachedPages.length > 0 ? cachedPages : propPages;
  const filteredPages = searchTerm ? searchPages(displayPages, searchTerm) : displayPages;
  const loading = isLoading || isLoadingPages;

  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Content className="notion-popup-modal">
        <div className="notion-popup-header">
          <div className="notion-popup-header-content">
            <img 
              src="/images/notion.png" 
              alt="Notion"
              className="notion-header-icon"
            />
            <Text className="notion-popup-title">Add to Knowledge Base</Text>
          </div>
          <button className="notion-popup-close" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        <div className="notion-search-container">
          <FaSearch className="notion-search-icon" />
          <input
            type="text"
            placeholder="Search for a page..."
            className="notion-search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="notion-popup-content">
          {loading ? (
            <div className="notion-loading">
              <div className="notion-loading-spinner"></div>
              <Text>Loading pages...</Text>
            </div>
          ) : filteredPages.length > 0 ? (
            <PageList 
              pages={filteredPages}
              selectedPages={selectedPages}
              onPageSelect={onPageSelect}
            />
          ) : (
            <div className="notion-empty-state">
              <Text>No pages found</Text>
              {searchTerm && (
                <Text size="2" color="gray">
                  Try a different search term
                </Text>
              )}
            </div>
          )}
        </div>

        <div className="notion-popup-footer">
          <div className="notion-selection-info">
            {selectedPages.length > 0 && (
              <Text size="2">
                {selectedPages.length} page{selectedPages.length !== 1 ? 's' : ''} selected
              </Text>
            )}
          </div>
          <button 
            className="notion-continue-button"
            disabled={selectedPages.length === 0}
            onClick={onContinue}
          >
            Add to Knowledge Base
          </button>
        </div>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default NotionPopup;