import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import '../styles/Knowledge.css';
import * as Tabs from '@radix-ui/react-tabs';
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Card, TextField, Select, Button, Table, Switch, IconButton, Flex, Tooltip, Box, Text, AlertDialog, Badge, ScrollArea, Callout, Spinner, DropdownMenu, Skeleton } from '@radix-ui/themes';
import { IoIosInformationCircleOutline, IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { IoArrowBack, IoSettingsSharp } from "react-icons/io5";
import { FaPlusSquare } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa6";
import DrivePopup from '../components/DrivePopup';
import NotionPopup from '../components/NotionPopup';
import Swal from 'sweetalert2'
import { toast, Bounce } from 'react-toastify';

const DRIVE_CACHE_KEY = 'drive_files_cache';
const NOTION_CACHE_KEY = 'notion_files_cache';
const DRIVE_CACHE_DURATION = 15 * 60 * 1000; // 15 minutes

const ALL_INTEGRATIONS = [
  { name: 'Google Drive', icon: 'google-drive.png', key: 'google' },
  // { name: 'Google Calendar', icon: 'google-calendar.png', key: 'google-calendar' },
  // { name: 'Gmail', icon: 'gmail.png', key: 'gmail' },
  // { name: 'OneDrive', icon: 'onedrive.png', key: 'onedrive' },
  { name: 'Notion', icon: 'notion.png', key: 'notion' },
  // { name: 'DropBox', icon: 'dropbox.png', key: 'dropbox' }
];

const DocumentsTable = ({ documents, isDocumentOwner, handleStatusChange, handleDelete, source, userId }) => {
  const [savedDriveRefs, setSavedDriveRefs] = useState([]);
  const [savedNotionRefs, setSavedNotionRefs] = useState([]);
  const [expandedDatabases, setExpandedDatabases] = useState({});
  const [expandedFolders, setExpandedFolders] = useState({});
  const filteredDocuments = documents.filter(doc => {
    const docTags = doc.tags || [];
    switch(source) {
      case 'local':
        return !docTags.includes('Google Drive Import') && !docTags.includes('Notion Import');
      case 'google-drive':
        return docTags.includes('Google Drive Import');
      case 'notion':
        return docTags.includes('Notion Import');
      default:
        return true;
    }
  });

  const toggleDatabase = (databaseId) => {
    setExpandedDatabases(prev => ({
      ...prev,
      [databaseId]: !prev[databaseId]
    }));
  };

  useEffect(() => {
    const fetchSavedRefs = async () => {
      if (source === 'google-drive') {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/google/saved-references`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          setSavedDriveRefs(response.data);
        } catch (error) {
          console.error('Error fetching saved references:', error);
        }
      }
    };

    fetchSavedRefs();
  }, [source]);

  useEffect(() => {
    const fetchSavedRefs = async () => {
      if (source === 'notion') {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/notion/saved-references`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          setSavedNotionRefs(response.data);
          console.log(savedNotionRefs);
        } catch (error) {
          console.error('Error fetching saved Notion references:', error);
        }
      }
    };

    fetchSavedRefs();
  }, [source]);

  const handleDriveReferenceDelete = async (ref) => {
    try {
      if (!ref._id) {
        console.error('No ref._id provided');
        return;
      }

      if (ref.type === 'folder') {
        const folderPath = ref.path;
        await axios.delete(
          `${process.env.REACT_APP_BACKEND}/api/google/references/${ref._id}?type=folder&path=${encodeURIComponent(folderPath)}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSavedDriveRefs(prev => prev.filter(item => !item.path.startsWith(folderPath)));
      } else {
        await axios.delete(
          `${process.env.REACT_APP_BACKEND}/api/google/references/${ref._id}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSavedDriveRefs(prev => prev.filter(item => item._id !== ref._id));
      }
      toast('Reference deleted successfully.', {
        theme: "light",
      });
    } catch (error) {
      console.error('Error deleting drive reference:', error);
      toast.error('Error deleting reference!', {
        theme: "colored",
      });
    }
  };

  const handleNotionReferenceDelete = async (ref) => {
    try {
      if (!ref._id) {
        console.error('No ref._id provided');
        return;
      }

      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/notion/references/${ref._id}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setSavedNotionRefs(prev => prev.filter(item => item._id !== ref._id));

      toast('Reference deleted successfully.', {
        theme: "light",
      });
    } catch (error) {
      console.error('Error deleting Notion reference:', error);
      toast.error('Error deleting reference!', {
        theme: "colored",
      });
    }
  };

  const formatSize = (bytes) => {
    if (!bytes) return 'N/A';
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  if (source === 'google-drive') {

    const toggleFolder = (path) => {
      setExpandedFolders(prev => ({
        ...prev,
        [path]: !prev[path]
      }));
    };

    // Filter out files that are within folders when displaying at root level
    const rootRefs = savedDriveRefs.filter(ref => {
      // If this is a file, check if its path exists as part of other folder references
      if (ref.type === 'file') {
        const parentPath = ref.path.substring(0, ref.path.lastIndexOf('/'));
        return !savedDriveRefs.some(otherRef => 
          otherRef.type === 'folder' && parentPath.startsWith(otherRef.path)
        );
      }
      // For folders, only show those that aren't nested inside other saved folders
      if (ref.type === 'folder') {
        return !savedDriveRefs.some(otherRef =>
          otherRef.type === 'folder' &&
          ref.path !== otherRef.path &&
          ref.path.startsWith(otherRef.path + '/')
        );
      }
      return true;
    });

    return (
      <ScrollArea>
        <Table.Root variant="surface" className="document-container">
          <Table.Header>
            <Table.Row className="table-header">
              <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Owner</Table.ColumnHeaderCell>
              {/*<Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Path</Table.ColumnHeaderCell>*/}
              <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Size</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Date</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          
          <Table.Body>
            {rootRefs.map((ref) => (
              <React.Fragment key={ref._id}>
                <Table.Row>
                  <Table.Cell>
                    <Flex align="center" gap="2">
                      {ref.type === 'folder' && (
                        <button
                          onClick={() => toggleFolder(ref.path)}
                          style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '4px' }}
                        >
                          {expandedFolders[ref.path] ? <IoIosArrowDown /> : <IoIosArrowForward />}
                        </button>//
                      )}
                      <img 
                        src={`/images/${ref.type === 'folder' ? 'folder.png' : 'file.png'}`} 
                        alt={ref.type}
                        className="file-type-icon" 
                        width={20}
                        height={20}
                      />
                      {ref.name}
                    </Flex>
                  </Table.Cell>
                  <Table.Cell>
                    {ref.savedBy?.firstName} {ref.savedBy?.lastName}
                  </Table.Cell>
                  {/*<Table.Cell>{ref.type}</Table.Cell>
                  <Table.Cell>{ref.path}</Table.Cell>*/}

                  <Table.Cell>
                    <Select.Root 
                      value={ref.status || 'Personal'}
                      onValueChange={(value) => handleStatusChange(ref._id, value, 'drive')}
                      disabled={ref.savedBy?._id !== userId}
                    >
                      <Select.Trigger />
                      <Select.Content>
                        <Select.Item value="Personal">Personal</Select.Item>
                        <Select.Item value="Public">Public</Select.Item>
                      </Select.Content>
                    </Select.Root>
                  </Table.Cell>
                  <Table.Cell>{formatSize(ref.size)}</Table.Cell>
                  <Table.Cell>
                    {new Date(ref.dateAdded).toLocaleDateString()}
                  </Table.Cell>
                  <Table.Cell>
                    <AlertDialog.Root>
                      <AlertDialog.Trigger>
                        <Button color="red" variant="soft">
                          Delete
                        </Button>
                      </AlertDialog.Trigger>
                      <AlertDialog.Content>
                        <AlertDialog.Title>Delete Reference</AlertDialog.Title>
                        <AlertDialog.Description>
                          Are you sure you want to delete this reference? This will not delete the file from Google Drive.
                        </AlertDialog.Description>
                        <Flex gap="3" mt="4" justify="end">
                          <AlertDialog.Cancel>
                            <Button variant="soft" color="gray">Cancel</Button>
                          </AlertDialog.Cancel>
                          <AlertDialog.Action>
                            <Button color="red" onClick={() => handleDriveReferenceDelete(ref)}>
                              Delete
                            </Button>
                          </AlertDialog.Action>
                        </Flex>
                      </AlertDialog.Content>
                    </AlertDialog.Root>
                  </Table.Cell>
                </Table.Row>
                {ref.type === 'folder' && expandedFolders[ref.path] && (
                  savedDriveRefs
                    .filter(childRef => 
                      childRef.path.startsWith(ref.path + '/') &&
                      childRef.path.slice(ref.path.length + 1).split('/').length === 1
                    )
                    .map(childRef => (
                      <Table.Row key={childRef._id} style={{ backgroundColor: 'rgb(250, 250, 250)' }}>
                        <Table.Cell>
                          <Flex align="center" gap="2" style={{ paddingLeft: '40px' }}>
                            {childRef.type === 'folder' && (
                              <button
                                onClick={() => toggleFolder(childRef.path)}
                                style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '4px' }}
                              >
                                {expandedFolders[childRef.path] ? <IoIosArrowDown /> : <IoIosArrowForward />}
                              </button>
                            )}
                            <img 
                              src={`/images/${childRef.type === 'folder' ? 'folder.png' : 'file.png'}`}
                              alt={childRef.type}
                              className="file-type-icon" 
                              width={20}
                              height={20}
                            />
                            {childRef.name}
                          </Flex>
                        </Table.Cell>
                        <Table.Cell>{childRef.type}</Table.Cell>
                        <Table.Cell>{childRef.path}</Table.Cell>
                        <Table.Cell>{formatSize(childRef.size)}</Table.Cell>
                        <Table.Cell>
                          {childRef.savedBy?.firstName} {childRef.savedBy?.lastName}
                        </Table.Cell>
                        <Table.Cell>
                          {new Date(childRef.dateAdded).toLocaleDateString()}
                        </Table.Cell>
                        <Table.Cell>
                          <AlertDialog.Root>
                            <AlertDialog.Trigger>
                              <Button color="red" variant="soft">
                                Delete
                              </Button>
                            </AlertDialog.Trigger>
                            <AlertDialog.Content>
                              <AlertDialog.Title>Delete Reference</AlertDialog.Title>
                              <AlertDialog.Description>
                                Are you sure you want to delete this reference? This will not delete the file from Google Drive.
                              </AlertDialog.Description>
                              <Flex gap="3" mt="4" justify="end">
                                <AlertDialog.Cancel>
                                  <Button variant="soft" color="gray">Cancel</Button>
                                </AlertDialog.Cancel>
                                <AlertDialog.Action>
                                  <Button color="red" onClick={() => handleDriveReferenceDelete(childRef)}>
                                    Delete
                                  </Button>
                                </AlertDialog.Action>
                              </Flex>
                            </AlertDialog.Content>
                          </AlertDialog.Root>
                        </Table.Cell>
                      </Table.Row>
                    ))
                )}
                {ref.type === 'folder' && expandedFolders[ref.path] && (
                  savedDriveRefs
                    .filter(childRef => 
                      childRef.path.startsWith(ref.path + '/') &&
                      childRef.path.slice(ref.path.length + 1).split('/').length === 1 &&
                      childRef.type === 'folder' &&
                      expandedFolders[childRef.path]
                    )
                    .flatMap(childFolder => 
                      savedDriveRefs
                        .filter(grandChild => 
                          grandChild.path.startsWith(childFolder.path + '/') &&
                          grandChild.path.slice(childFolder.path.length + 1).split('/').length === 1
                        )
                        .map(grandChild => (
                          <Table.Row key={grandChild._id} style={{ backgroundColor: 'rgb(245, 245, 245)', paddingLeft: '80px' }}>
                            <Table.Cell>
                              <Flex align="center" gap="2" style={{ paddingLeft: '80px' }}>
                                {grandChild.type === 'folder' && (
                                  <button
                                    onClick={() => toggleFolder(grandChild.path)}
                                    style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '4px' }}
                                  >
                                    {expandedFolders[grandChild.path] ? <IoIosArrowDown /> : <IoIosArrowForward />}
                                  </button>
                                )}
                                <img 
                                  src={`/images/${grandChild.type === 'folder' ? 'folder.png' : 'file.png'}`}
                                  alt={grandChild.type}
                                  className="file-type-icon" 
                                  width={20}
                                  height={20}
                                />
                                {grandChild.name}
                              </Flex>
                            </Table.Cell>
                            <Table.Cell>{grandChild.type}</Table.Cell>
                            <Table.Cell>{grandChild.path}</Table.Cell>
                            <Table.Cell>{formatSize(grandChild.size)}</Table.Cell>
                            <Table.Cell>
                              {grandChild.savedBy?.firstName} {grandChild.savedBy?.lastName}
                            </Table.Cell>
                            <Table.Cell>
                              {new Date(grandChild.dateAdded).toLocaleDateString()}
                            </Table.Cell>
                            <Table.Cell>
                              <AlertDialog.Root>
                                <AlertDialog.Trigger>
                                  <Button color="red" variant="soft">
                                    Delete
                                  </Button>
                                </AlertDialog.Trigger>
                                <AlertDialog.Content>
                                  <AlertDialog.Title>Delete Reference</AlertDialog.Title>
                                  <AlertDialog.Description>
                                    Are you sure you want to delete this reference? This will not delete the file from Google Drive.
                                  </AlertDialog.Description>
                                  <Flex gap="3" mt="4" justify="end">
                                    <AlertDialog.Cancel>
                                      <Button variant="soft" color="gray">Cancel</Button>
                                    </AlertDialog.Cancel>
                                    <AlertDialog.Action>
                                      <Button color="red" onClick={() => handleDriveReferenceDelete(grandChild)}>
                                        Delete
                                      </Button>
                                    </AlertDialog.Action>
                                  </Flex>
                                </AlertDialog.Content>
                              </AlertDialog.Root>
                            </Table.Cell>
                          </Table.Row>
                        ))
                    )
                )}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table.Root>
      </ScrollArea>
    );
  }

  if (source === 'notion') {
    return (
      <ScrollArea>
        <Table.Root variant="surface" className="document-container">
          <Table.Header>
            <Table.Row className="table-header">
              <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
              {/*<Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Path</Table.ColumnHeaderCell>*/}
              <Table.ColumnHeaderCell>Owner</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
              {/*<Table.ColumnHeaderCell>Size</Table.ColumnHeaderCell>*/}
              <Table.ColumnHeaderCell>Date</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          
          <Table.Body>
            {savedNotionRefs.map((ref) => (
              <React.Fragment key={ref._id}>
                <Table.Row>
                  <Table.Cell>
                    <Flex align="center" gap="2">
                      {ref.type === 'database' && (
                        <button
                          onClick={() => toggleDatabase(ref._id)}
                          className="notion-expand-button"
                          style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                          <IoIosArrowForward 
                            className={`notion-arrow ${expandedDatabases[ref._id] ? 'expanded' : ''}`}
                            size={16}
                          />
                        </button>
                      )}
                      <img 
                        src={`/images/${ref.type === 'database' ? 'database.png' : 'file.png'}`}
                        alt={ref.type}
                        className="file-type-icon" 
                        width={20}
                        height={20}
                      />
                      {ref.name}
                      {ref.type === 'database' && (
                        <Badge size="1" variant="outline">
                          {ref.totalItems} items
                        </Badge>
                      )}
                    </Flex>
                  </Table.Cell>
                  {/*<Table.Cell>{ref.type}</Table.Cell>
                  <Table.Cell>{ref.path}</Table.Cell>*/}
                  <Table.Cell>
                    {ref.savedBy?.firstName} {ref.savedBy?.lastName}
                  </Table.Cell>
                  <Table.Cell>
                    <Select.Root 
                      value={ref.status || 'Personal'}
                      onValueChange={(value) => handleStatusChange(ref._id, value, 'notion')}
                      disabled={ref.savedBy?._id !== userId} // Only owner can change status
                    >
                      <Select.Trigger />
                      <Select.Content>
                        <Select.Item value="Personal">Personal</Select.Item>
                        <Select.Item value="Public">Public</Select.Item>
                      </Select.Content>
                    </Select.Root>
                  </Table.Cell>
                  {/*<Table.Cell>{formatSize(ref.size)}</Table.Cell>*/}
                  <Table.Cell>
                    {new Date(ref.dateAdded).toLocaleDateString()}
                  </Table.Cell>
                  <Table.Cell>
                    <AlertDialog.Root>
                      <AlertDialog.Trigger>
                        <Button color="red" variant="soft">
                          Delete
                        </Button>
                      </AlertDialog.Trigger>
                      <AlertDialog.Content>
                        <AlertDialog.Title>Delete Reference</AlertDialog.Title>
                        <AlertDialog.Description>
                          {ref.type === 'database' 
                            ? `Are you sure you want to delete this database reference? This contains ${ref.totalItems} items.`
                            : 'Are you sure you want to delete this page reference?'} 
                          This will not delete the content from Notion.
                        </AlertDialog.Description>
                        <Flex gap="3" mt="4" justify="end">
                          <AlertDialog.Cancel>
                            <Button variant="soft" color="gray">Cancel</Button>
                          </AlertDialog.Cancel>
                          <AlertDialog.Action>
                            <Button color="red" onClick={() => handleNotionReferenceDelete(ref)}>
                              Delete
                            </Button>
                          </AlertDialog.Action>
                        </Flex>
                      </AlertDialog.Content>
                    </AlertDialog.Root>
                  </Table.Cell>
                </Table.Row>
                {ref.type === 'database' && expandedDatabases[ref._id] && ref.pages?.map(page => (
                  <Table.Row key={page.id} style={{ backgroundColor: 'rgb(250, 250, 250)' }}>
                    <Table.Cell>
                      <Flex align="center" gap="2" style={{ paddingLeft: '40px' }}>
                        <img 
                          src="/images/file.png"
                          alt="page"
                          className="file-type-icon" 
                          width={16}
                          height={16}
                        />
                        {page.title}
                      </Flex>
                    </Table.Cell>
                    <Table.Cell>page</Table.Cell>
                    <Table.Cell>{ref.path}/{page.title}</Table.Cell>
                    <Table.Cell>{formatSize(page.size)}</Table.Cell>
                    <Table.Cell>
                      {new Date(page.lastModified).toLocaleDateString()}
                    </Table.Cell>
                    <Table.Cell>-</Table.Cell>
                  </Table.Row>
                ))}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table.Root>
      </ScrollArea>
    );
  }

  return (
    <ScrollArea>
      <Table.Root variant="surface" className="document-container">
        <Table.Header>
          <Table.Row className="table-header">
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Owner</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Size</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Date</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Tags</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        
        <Table.Body>
          {filteredDocuments.map((doc) => (
            <Table.Row key={doc._id}>
              <Table.Cell>{doc.name}</Table.Cell>
              <Table.Cell>
                {doc.owner ? `${doc.owner.firstName} ${doc.owner.lastName}` : 'Loading'}
              </Table.Cell>
              <Table.Cell>
                <Select.Root 
                  value={doc.status}
                  onValueChange={(value) => handleStatusChange(doc._id, value)}
                  disabled={!isDocumentOwner(doc)}
                >
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Item value="Personal">Personal</Select.Item>
                    <Select.Item value="Public">Public</Select.Item>
                  </Select.Content>
                </Select.Root>
              </Table.Cell>
              <Table.Cell>{Math.round(doc.size / 1024)} KB</Table.Cell>
              <Table.Cell>{new Date(doc.dateAdded).toLocaleDateString()}</Table.Cell>
              <Table.Cell>
                <Flex gap="1" wrap="wrap">
                  {doc.tags.map((tag, index) => (
                    <Badge key={index} size="1">{tag}</Badge>
                  ))}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <Button 
                      color="red" 
                      variant="soft"
                      disabled={!isDocumentOwner(doc)}
                    >
                      Delete
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content>
                    <AlertDialog.Title>Delete Document</AlertDialog.Title>
                    <AlertDialog.Description>
                      Are you sure you want to delete this document? This action cannot be undone.
                    </AlertDialog.Description>
                    <Flex gap="3" mt="4" justify="end">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                          Cancel
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Action>
                        <Button 
                          color="red" 
                          onClick={() => handleDelete(doc._id)}
                        >
                          Delete
                        </Button>
                      </AlertDialog.Action>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </ScrollArea>
  );
};//

const Knowledge = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  // const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState('');
  const [status, setStatus] = useState('Personal');
  const [selectedItemStatuses, setSelectedItemStatuses] = useState({});
  const [tags, setTags] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const { userFirstName, userLastName, companyId, userId } = useAuth();
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [connectedIntegrations, setConnectedIntegrations] = useState([]);
  const [enabledIntegrations, setEnabledIntegrations] = useState({});
  const tagOptions = ['Sales', 'Marketing', 'Dev', 'Product', 'Business', 'Finance', 'Accounting', 'Industry knowledge', 'Product knowledge', 'Regulations'];
  // Google Drive
  const [showGoogleDrivePopup, setShowGoogleDrivePopup] = useState(false);
  const [selectedGoogleFile, setSelectedGoogleFile] = useState(null);
  const [selectedGoogleFiles, setSelectedGoogleFiles] = useState([]);
  const [googleDriveFiles, setGoogleDriveFiles] = useState([]);
  const [selectedGoogleItems, setSelectedGoogleItems] = useState([]);
  // OneDrive
  const [showOneDrivePopup, setShowOneDrivePopup] = useState(false);
  const [selectedOneDriveFile, setSelectedOneDriveFile] = useState(null);
  const [selectedOneDriveFiles, setSelectedOneDriveFiles] = useState([]);
  const [oneDriveFiles, setOneDriveFiles] = useState([]);

  // Notion
  const [showNotionPopup, setShowNotionPopup] = useState(false);
  const [selectedNotionPages, setSelectedNotionPages] = useState([]);
  const [notionPages, setNotionPages] = useState([]);

  // Upload
  const [fileProgress, setFileProgress] = useState({});
  const [overallProgress, setOverallProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const inputSectionRef = useRef(null);
  const fileInputRef = useRef(null);

  // Excluded MIME types
  const [excludedMimeTypes, setExcludedMimeTypes] = useState([
    // 'application/vnd.google-apps.folder',
    'application/vnd.google-apps.shortcut',
    // 'image/svg+xml',
    // 'image/png'
  ]);
  

  useEffect(() => {
    fetchDocuments();
    fetchConnectedIntegrations();
  }, []);


  const selectGoogleDriveFile = (file) => {
    setSelectedGoogleFiles(prev => {
      const isAlreadySelected = prev.some(f => f.id === file.id);
      if (isAlreadySelected) {
        return prev.filter(f => f.id !== file.id);
      }
      const newFiles = [...prev, file];
      // Set filename if only one file is selected
      if (newFiles.length === 1) {
        setFileName(file.name);
      } else {
        setFileName('');
      }
      return newFiles;
    });
  };

  // Update the selectOneDriveFile function similarly
  const selectOneDriveFile = (file) => {
    setSelectedOneDriveFiles(prev => {
      const isAlreadySelected = prev.some(f => f.id === file.id);
      if (isAlreadySelected) {
        return prev.filter(f => f.id !== file.id);
      }
      const newFiles = [...prev, file];
      // Set filename if only one file is selected
      if (newFiles.length === 1) {
        setFileName(file.name);
      } else {
        setFileName('');
      }
      return newFiles;
    });
  };

  const fetchDocuments = async () => {
    try {
      setIsLoadingDocuments(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/documents`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setIsLoadingDocuments(false);
    }
  };


  const fetchConnectedIntegrations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/integrations/connected`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      const filteredIntegrations = response.data.filter(integration => 
        integration !== 'google-calendar' && integration !== 'gmail'
      );
      
      // Initialize enabled state only for connected integrations
      const initialEnabledState = {};
      filteredIntegrations.forEach(integration => {
        initialEnabledState[integration] = true;
      });
      setEnabledIntegrations(initialEnabledState);
      
      setConnectedIntegrations(filteredIntegrations);
    } catch (error) {
      console.error('Error fetching connected integrations:', error);
    }
  };

  // Toggle handler
  const handleIntegrationToggle = (integration) => {
    setEnabledIntegrations(prev => ({
      ...prev,
      [integration]: !prev[integration]
    }));
  };

  // const handleImportFromIntegration = async (integration) => {
  //   if (integration === 'google') {
  //     setShowGoogleDrivePopup(true);
  //     fetchGoogleDriveFiles();
  //   } else if (integration === 'onedrive') {
  //     setShowOneDrivePopup(true);
  //     fetchOneDriveFiles();
  //   } else if (integration === 'notion') {
  //     setShowNotionPopup(true);
  //     fetchNotionPages();
  //   }
  //   setShowIntegrations(false);
  // };

  const handleGoogleItemSelect = (item) => {
    setSelectedGoogleItems(prev => {
      const isAlreadySelected = prev.some(i => i.id === item.id);
      if (isAlreadySelected) {
        return prev.filter(i => i.id !== item.id);
      }
      return [...prev, item];
    });
  };

  const handleSaveGoogleReferences = async () => {
    try {
      setIsLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/google/save-references`,
        { items: selectedGoogleItems },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );

      setShowGoogleDrivePopup(false);
      setSelectedGoogleItems([]);
      toast('References saved successfully!', {
        theme: "light",
      });
    } catch (error) {
      console.error('Error saving Google Drive references:', error);
      toast.error('Failed to save references!', {
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
      fetchDocuments();
    }
  };

  const handleSaveNotionReferences = async () => {
    try {
      setIsLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/notion/save-references`,
        { pages: selectedNotionPages },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );

      setShowNotionPopup(false);
      setSelectedNotionPages([]);
      
      toast('References saved successfully!', {
        theme: "light",
      });
    } catch (error) {
      console.error('Error saving Notion references:', error);
      toast.error('Failed to save references!', {
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOneDriveImport = async () => {
    // try {
    //   setIsLoading(true);
    //   await axios.post(
    //     `${process.env.REACT_APP_BACKEND}/api/google/save-references`,
    //     { items: selectedGoogleItems },
    //     {
    //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    //     }
    //   );

    //   setShowGoogleDrivePopup(false);
    //   setSelectedGoogleItems([]);
      
    //   Swal.fire({
    //     icon: "success",
    //     title: "References saved successfully!",
    //     showConfirmButton: false,
    //     timer: 1500
    //   });
    // } catch (error) {
    //   console.error('Error saving Google Drive references:', error);
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Failed to save references!",
    //     showConfirmButton: false,
    //     timer: 3000
    //   });
    // } finally {
    //   setIsLoading(false);
    // }
  };

  // const fetchGoogleDriveFiles = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/google/files`, {
  //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //     });

  //     // console.log('Raw Google Drive response:', response.data);
  //     // Check the structure of parents
  //     response.data.forEach(file => {
  //       // console.log('File:', file.name, 'Parents:', file.parents);
  //     });
  //     // Filter out files with excluded MIME types
  //     const filteredFiles = response.data.filter(file => 
  //       !excludedMimeTypes.some(mimeType => file.mimeType.includes(mimeType))
  //     );
  //     setGoogleDriveFiles(filteredFiles);
  //     // setGoogleDriveFiles(response.data);
  //   } catch (error) {
  //     console.error('Error fetching Google Drive files:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };


  const fetchGoogleDriveFiles = async () => {
    try {
      // Check cache first
      const cached = localStorage.getItem(DRIVE_CACHE_KEY);
      if (cached) {
        const { data, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp < DRIVE_CACHE_DURATION) {
          const filteredFiles = data.filter(file => 
            !excludedMimeTypes.some(mimeType => file.mimeType.includes(mimeType))
          );
          setGoogleDriveFiles(filteredFiles);
          return;
        }
      }

      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/google/files`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      // Cache the response
      localStorage.setItem(DRIVE_CACHE_KEY, JSON.stringify({
        data: response.data,
        timestamp: Date.now()
      }));

      const filteredFiles = response.data.filter(file => 
        !excludedMimeTypes.some(mimeType => file.mimeType.includes(mimeType))
      );
      setGoogleDriveFiles(filteredFiles);
    } catch (error) {
      console.error('Error fetching Google Drive files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOneDriveFiles = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/onedrive/files`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const filteredFiles = response.data.filter(file => 
        !excludedMimeTypes.some(mimeType => file.file && file.file.mimeType.includes(mimeType))
      );
      setOneDriveFiles(filteredFiles);
    } catch (error) {
      console.error('Error fetching OneDrive files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // const fetchNotionPages = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/notion/pages`, {
  //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //     });
  //     setNotionPages(response.data);
  //   } catch (error) {
  //     console.error('Error fetching Notion pages:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchNotionPages = async () => {
    try {
      const cached = localStorage.getItem(NOTION_CACHE_KEY);
      if (cached) {
        const { data, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp < DRIVE_CACHE_DURATION) {
          setNotionPages(data);
          return;
        }
      }

      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/notion/pages`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      localStorage.setItem(NOTION_CACHE_KEY, JSON.stringify({
        data: response.data,
        timestamp: Date.now()
      }));
      setNotionPages(response.data);
    } catch (error) {
      console.error('Error fetching Notion pages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectNotionPage = (page) => {
    setSelectedNotionPages(prev => {
      const isAlreadySelected = prev.some(p => p.id === page.id);
      if (isAlreadySelected) {
        return prev.filter(p => p.id !== page.id);
      }
      const newPages = [...prev, page];
      if (newPages.length === 1) {
        setFileName(page.title);
      } else {
        setFileName('');
      }
      return newPages;
    });
  };

  const importFile = async (fileId, fileName, integration) => {
    try {

      // Get file metadata first to check size
      let fileMetadata;
      if (integration === 'google') {
        fileMetadata = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/google/file/${fileId}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      } else if (integration === 'onedrive') {
        fileMetadata = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/onedrive/file/${fileId}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      }

      if (fileMetadata.data.size > 5 * 1024 * 1024) {
        toast.error('Maximum file size is 5MB!', {
          theme: "colored",
        });
        return;
      }

      setIsUploading(true);
      let response;
      if (integration === 'google') {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/google/import`,
          { fileId },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      } else if (integration === 'onedrive') {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/onedrive/import`,
          { fileId },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      }
      setDocuments([...documents, response.data.document]);
      setShowGoogleDrivePopup(false);
      setShowOneDrivePopup(false);
      alert('File imported successfully!');
      fetchDocuments();
    } catch (error) {
      console.error('Error importing file:', error);
      alert('Error importing file. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };


  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const initialProgress = {};
    selectedFiles.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setFileProgress(prev => ({...prev, ...initialProgress}));
    setFiles(selectedFiles);
    
    // If multiple files are selected, disable the filename input
    if (selectedFiles.length > 1) {
      setFileName('');
    } else if (selectedFiles.length === 1) {
      setFileName(selectedFiles[0].name);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };


  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const droppedFiles = Array.from(e.dataTransfer.files);
    const oversizedFiles = droppedFiles.filter(file => file.size > 5 * 1024 * 1024);
    
    if (oversizedFiles.length > 0) {
      toast.error('Maximum file size is 5MB!', {
        theme: "colored",
      });
      return;
    }

    if (droppedFiles.length > 0) {
      const initialProgress = {};
      droppedFiles.forEach(file => {
        initialProgress[file.name] = 0;
      });
      setFileProgress(prev => ({...prev, ...initialProgress}));
      setFiles(droppedFiles);
      
      if (droppedFiles.length === 1) {
        setFileName(droppedFiles[0].name);
      } else {
        setFileName('');
      }
      setStatus('Personal');
      setTags([]);
    }
  }, []);


  const handleUpload = async () => {
    const hasLocalFiles = files.length > 0;
    const hasGoogleFiles = selectedGoogleFiles.length > 0;
    const hasOneDriveFiles = selectedOneDriveFiles.length > 0;
    const hasNotionPages = selectedNotionPages.length > 0;
    
    if (!hasLocalFiles && !hasGoogleFiles && !hasOneDriveFiles && !hasNotionPages) return;

    // Check sizes for all files
    const allFiles = [
      ...Array.from(files),
      ...selectedGoogleFiles,
      ...selectedOneDriveFiles,
      ...selectedNotionPages
    ];

    const oversizedFiles = allFiles.filter(file => file.size > 5 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      toast.error('Maximum file size is 5MB!', {
        theme: "colored",
      });
      return;
    }

    setIsUploading(true);
    setOverallProgress(0);

    try {
      const totalFiles = files.length + selectedGoogleFiles.length + selectedOneDriveFiles.length + selectedNotionPages.length;
      let completedFiles = 0;

      // Upload Notion files
      for (const page of selectedNotionPages) {
        try {
          setFileProgress(prev => ({
            ...prev,
            [page.title]: 0
          }));

          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND}/api/notion/import`,
            { 
              pageId: page.id,
              name: selectedNotionPages.length === 1 ? fileName : page.title,
              status: status,
              tags: tags,
              companyId: companyId
            },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem('token')}` 
              }
            }
          );

          completedFiles++;
          setOverallProgress((completedFiles / totalFiles) * 100);
          setFileProgress(prev => ({
            ...prev,
            [page.title]: 100
          }));

          if (response.data && response.data.document) {
            setDocuments(prev => [...prev, response.data.document]);
          }
        } catch (error) {
          console.error(`Error importing Notion page ${page.title}:`, error);
        }
      }

      // Upload Google Drive files
      for (const file of selectedGoogleFiles) {
        try {
          setFileProgress(prev => ({
            ...prev,
            [file.name]: 0
          }));

          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND}/api/google/import`,
            { 
              fileId: file.id,
              name: selectedGoogleFiles.length === 1 ? fileName : file.name,
              status: status,
              tags: tags,
              companyId: companyId
            },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem('token')}` 
              }
            }
          );

          completedFiles++;
          setOverallProgress((completedFiles / totalFiles) * 100);
          setFileProgress(prev => ({
            ...prev,
            [file.name]: 100
          }));

          if (response.data && response.data.document) {
            setDocuments(prev => [...prev, response.data.document]);
          }
        } catch (error) {
          console.error(`Error uploading Google Drive file ${file.name}:`, error);
        }
      }

      // Upload OneDrive files
      for (const file of selectedOneDriveFiles) {
        try {
          setFileProgress(prev => ({
            ...prev,
            [file.name]: 0
          }));

          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND}/api/onedrive/import`,
            { 
              fileId: file.id,
              name: selectedOneDriveFiles.length === 1 ? fileName : file.name,
              status: status,
              tags: tags,
              companyId: companyId
            },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem('token')}` 
              }
            }
          );

          completedFiles++;
          setOverallProgress((completedFiles / totalFiles) * 100);
          setFileProgress(prev => ({
            ...prev,
            [file.name]: 100
          }));

          if (response.data && response.data.document) {
            setDocuments(prev => [...prev, response.data.document]);
          }
        } catch (error) {
          console.error(`Error uploading OneDrive file ${file.name}:`, error);
        }
      }

      // Upload local files
      for (const file of files) {
        try {
          setFileProgress(prev => ({
            ...prev,
            [file.name]: 0
          }));

          const formData = new FormData();
          formData.append('file', file);
          formData.append('name', files.length === 1 ? fileName : file.name);
          formData.append('status', status);
          formData.append('tags', JSON.stringify(tags));
          formData.append('companyId', companyId);

          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND}/api/documents/upload`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setFileProgress(prev => ({
                  ...prev,
                  [file.name]: progress
                }));
              }
            }
          );

          completedFiles++;
          setOverallProgress((completedFiles / totalFiles) * 100);
          
          if (response.data && response.data.document) {
            setDocuments(prev => [...prev, response.data.document]);
          }
        } catch (error) {
          console.error(`Error uploading local file ${file.name}:`, error);
        }
      }

      // Reset all states after completion
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset file input
      }
      setFiles([]);
      setSelectedGoogleFiles([]);
      setSelectedOneDriveFiles([]);
      setSelectedNotionPages([]);
      setFileName('');
      setStatus('Personal');
      setTags([]);
      setFileProgress({});
      setShowGoogleDrivePopup(false);
      setShowOneDrivePopup(false);
      fetchDocuments();
      // alert('Files uploaded successfully!');
      toast('Files uploaded successfully.', {
        theme: "light",
      });
    } catch (error) {
      console.error('Error uploading files:', error);
      // alert('Error uploading files. Please try again.');
      toast.error('Files faced an error during upload!', {
        theme: "colored",
      });
    } finally {
      setIsUploading(false);
      setOverallProgress(0);
      if (inputSectionRef.current) {
        inputSectionRef.current.classList.remove('uploading');
      }
    }
  };

  const handleTagChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setTags([...tags, value]);
    } else {
      setTags(tags.filter(tag => tag !== value));
    }
  };

  const handleStatusChange = async (id, newStatus, type = 'local') => {
    try {
      if (type === 'drive') {
        const response = await axios.patch(
          `${process.env.REACT_APP_BACKEND}/api/google/references/${id}/status`,
          { status: newStatus },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        // Update the local state with the new status
        setDocuments(prev => prev.map(doc => 
          doc._id === id ? { ...doc, status: newStatus } : doc
        ));
      } else if (type === 'notion'){
        const response = await axios.patch(
          `${process.env.REACT_APP_BACKEND}/api/notion/references/${id}/status`,
          { status: newStatus },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setDocuments(prev => prev.map(doc => 
          doc._id === id ? { ...doc, status: newStatus } : doc
        ));
      } else {
        const response = await axios.patch(`${process.env.REACT_APP_BACKEND}/api/documents/${id}`, { status: newStatus }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      
        // Ensure the response includes the full document with owner information
        if (response.data && response.data.owner) {
          setDocuments(documents.map(doc => doc._id === id ? {
            ...doc,
            status: response.data.status,
            owner: response.data.owner
          } : doc));
        } else {
          console.error('Incomplete document data received from server');
        }
      }
      fetchDocuments();
    } catch (error) {
      console.error('Error updating document status:', error);
      fetchDocuments();
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/documents/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setDocuments(documents.filter(doc => doc._id !== id));
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleRemoveFile = (indexToRemove) => {
    const newFiles = Array.from(files).filter((_, index) => index !== indexToRemove);
    setFiles(newFiles);
    
    // Reset filename if all files are removed or only one file remains
    if (newFiles.length === 0) {
      setFileName('');
    } else if (newFiles.length === 1) {
      setFileName(newFiles[0].name);
    }
  };

  const isDocumentOwner = (doc) => {
    return doc.owner && doc.owner.firstName === userFirstName && doc.owner.lastName === userLastName;
  };

  return (
    <Box className="knowledge" p="6">
      <h1>
        Increase your AI knowledge base
      </h1>
      
      <Flex gap="4" mb="6">
        {/* Left Card - Input Section */}
        <label htmlFor="file-upload" style={{ width: '50%', cursor: 'pointer' }}>
          <Card 
            size="3" 
            className={`card-flex-container-left ${isDragging ? 'dragging' : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              ref={fileInputRef}
              type="file"
              multiple
              onChange={handleFileChange}
              className="file-input-custom"
              style={{ display: 'none' }}
              id="file-upload"
            />
            
            <Box ref={inputSectionRef} className="drag-section">
              <AiOutlineCloudUpload size={29} color="#0D00FF" className="drag-icon"/>
              <Text className="drag-title">
                Drag & drop files here or click anywhere to browse
              </Text>
            </Box>

            <Flex direction="column" gap="3" style={{ width: '100%' }} onClick={e => e.stopPropagation()}>
              <TextField.Root
                placeholder="File name" 
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                disabled={files.length > 1 || selectedGoogleFiles.length > 1 || selectedOneDriveFiles.length > 1}
              />
              
              <Select.Root value={status} onValueChange={setStatus}>
                <Select.Trigger />
                <Select.Content>
                  <Select.Item value="Personal">Personal</Select.Item>
                  <Select.Item value="Public">Public</Select.Item>
                </Select.Content>
              </Select.Root>

              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <Button variant="surface" size="2">
                    {tags.length === 0 ? 'Select tags...' : `${tags.length} tag${tags.length === 1 ? '' : 's'} selected`}
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  {tagOptions.map((tag) => (
                    <DropdownMenu.Item
                      key={tag}
                      onSelect={(event) => {
                        event.preventDefault();
                        handleTagChange({ target: { value: tag, checked: !tags.includes(tag) } });
                      }}
                    >
                      <Flex gap="2" align="center">
                        <input
                          type="checkbox"
                          checked={tags.includes(tag)}
                          onChange={() => {}}
                          onClick={(e) => e.stopPropagation()}
                        />
                        {tag}
                      </Flex>
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Content>
              </DropdownMenu.Root>

              {files.length > 0 && (
                <Box>
                  <Text size="2" style={{ color: '#666', marginBottom: '4px' }}>
                    {files.length} file{files.length === 1 ? '' : 's'} selected:
                  </Text>
                  <Flex direction="column" gap="1">
                    {Array.from(files).map((file, index) => (
                      <Flex key={index} align="center" gap="2">
                        <Text size="2" style={{ color: '#666' }}>
                          {file.name}
                        </Text>
                        <Button 
                          size="1" 
                          variant="ghost" 
                          className="take-out-file-import"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveFile(index);
                          }}
                        >
                          ×
                        </Button>
                      </Flex>
                    ))}
                  </Flex>
                </Box>
              )}

              {files.length < 1 && (
                <Callout.Root>
                  <Callout.Icon>
                    <IoIosInformationCircleOutline />
                  </Callout.Icon>
                  <Callout.Text>
                    Maximum file size is limited to 5 MB for now.
                  </Callout.Text>
                </Callout.Root>
              )}

              <Button 
                className={`upload-button ${isUploading ? 'disabled' : ''}`}
                onClick={handleUpload} 
                disabled={isUploading}
                size="3"
              >
                {isUploading ? (
                  <Flex align="center" gap="2">
                    <span>Uploading...</span>
                    <Text size="2">
                      ({Math.ceil(overallProgress / (100 / files.length))}/{files.length})
                    </Text>
                  </Flex>
                ) : (
                  'Upload Document'
                )}
              </Button>
            </Flex>
          </Card>
        </label>

        {/* Right Card - Integrations */}
        <Card size="3" className="card-flex-container">
          <Flex justify="between" align="center" mb="4">
            <Text size="5" weight="bold">Import Data From:</Text>
            <Button 
              onClick={() => navigate('/integrations')}
              variant="solid"
              className="manage-integrations-button"
            >
              <Flex align="center" gap="2">
                <IoSettingsSharp />
                Manage Integrations
              </Flex>
            </Button>
          </Flex>
          <Flex direction="column" gap="4">
            {ALL_INTEGRATIONS.map((integration) => {
              const isConnected = connectedIntegrations.includes(integration.key);

              return (
                <Flex key={integration.key} justify="between" align="center" className="integration-row">
                  <Flex align="center" gap="2">
                    <img
                      src={`/images/${integration.icon}`}
                      alt={`${integration.name} icon`}
                      className="integration-icon-small"
                    />
                    <Text size="2">{integration.name}</Text>
                  </Flex>
                  <Flex align="center" gap="2">
                    <Badge color={isConnected ? "green" : "red"} className="knowledge-badge">
                      {isConnected ? "Connected" : "Not Connected"}
                    </Badge>
                    {isConnected && (
                      <Tooltip content="Import files">
                        <Button 
                          variant="ghost" 
                          size="3"
                          onClick={() => {
                            if (integration.key === 'google') {
                              setShowGoogleDrivePopup(true);
                              fetchGoogleDriveFiles();
                            } else if (integration.key === 'notion') {
                              setShowNotionPopup(true);
                              fetchNotionPages();
                            }
                          }}
                        >
                          <FaPlusSquare />
                        </Button>
                      </Tooltip>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Card>
      </Flex>

      {/* Documents Table */}
      <Box mb="6">
        <h2>Documents</h2>
        {isLoadingDocuments ? (
          <div className="analyzing-container">
            <video 
              autoPlay 
              loop 
              muted 
              playsInline
              className="loader-video"
            >
              <source src="/loader.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (

          <Tabs.Root defaultValue="local">
            <Tabs.List className="TabsList" aria-label="Document sources">
              <Tabs.Trigger className="TabsTrigger" value="local">
                <FaFolderOpen className="source-icon"/> Local Files
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="google-drive">
                <img src="/images/google-drive.png" className="source-icon" alt="Google Drive" /> 
                <span>Google Drive</span>
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="notion">
                <img src="/images/notion.png" className="source-icon" alt="Notion" /> 
                <span>Notion</span>
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content className="TabsContent" value="local">
              <DocumentsTable 
                documents={documents}
                isDocumentOwner={isDocumentOwner}
                handleStatusChange={handleStatusChange}
                handleDelete={handleDelete}
                source="local"
              />
            </Tabs.Content>

            <Tabs.Content className="TabsContent" value="google-drive">
              <DocumentsTable 
                documents={documents}
                isDocumentOwner={isDocumentOwner}
                handleStatusChange={handleStatusChange}
                handleDelete={handleDelete}
                source="google-drive"
                userId={userId}
              />
            </Tabs.Content>

            <Tabs.Content className="TabsContent" value="notion">
              <DocumentsTable 
                documents={documents}
                isDocumentOwner={isDocumentOwner}
                handleStatusChange={handleStatusChange}
                handleDelete={handleDelete}
                source="notion"
                userId={userId}
              />
            </Tabs.Content>
          </Tabs.Root>
        )}
      </Box>
      {/* Add Google Drive and OneDrive popups */}
      {(showGoogleDrivePopup || showOneDrivePopup) && (
        <DrivePopup
          isOpen={showGoogleDrivePopup || showOneDrivePopup}
          onClose={() => {
            setShowGoogleDrivePopup(false);
            setShowOneDrivePopup(false);
            setSelectedGoogleItems([]);
            setSelectedOneDriveFiles([]);
          }}
          files={showGoogleDrivePopup ? googleDriveFiles : oneDriveFiles}
          selectedItems={showGoogleDrivePopup ? selectedGoogleItems : selectedOneDriveFiles}
          onItemSelect={showGoogleDrivePopup ? handleGoogleItemSelect : selectOneDriveFile}
          onSave={showGoogleDrivePopup ? handleSaveGoogleReferences : handleOneDriveImport}
          integration={showGoogleDrivePopup ? 'google' : 'onedrive'}
          isLoading={isLoading}
        />
      )}
      {showNotionPopup && (
        <NotionPopup
          isOpen={showNotionPopup}
          onClose={() => {
            setShowNotionPopup(false);
            setSelectedNotionPages([]);
          }}
          pages={notionPages}
          selectedPages={selectedNotionPages}
          onPageSelect={selectNotionPage}
          onContinue={handleSaveNotionReferences}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default Knowledge;



