// FormatMessage.jsx
import React, { useState } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/base16/synth-midnight-terminal-dark.css';
import { Button } from '@radix-ui/themes';
import { LuCopy } from "react-icons/lu";
import GraphRenderer from './GraphRenderer';

const formatInlineElements = (text) => {
  const parts = text.split(/(\*\*|\*|`|\[.*?\]\(.*?\))/);
  let inBold = false;
  let inItalic = false;
  let inCode = false;
  let codeContent = '';

  return parts.map((part, index) => {
    if (part === '**') {
      inBold = !inBold;
      return null;
    } else if (part === '*') {
      inItalic = !inItalic;
      return null;
    } else if (part === '`') {
      if (inCode) {
        const highlightedCode = hljs.highlightAuto(codeContent).value;
        inCode = false;
        const result = <code key={index} className="inline-code" dangerouslySetInnerHTML={{ __html: highlightedCode }} />;
        codeContent = '';
        return result;
      } else {
        inCode = true;
        return null;
      }
    } else if (part.startsWith('[') && part.endsWith(')')) {
      const [linkText, url] = part.slice(1, -1).split('](');
      return <a key={index} href={url} target="_blank" rel="noopener noreferrer">{linkText}</a>;
    } else if (part.startsWith('[ACTION:') && part.endsWith(']')) {
      return '';
    } else {
      if (inCode) {
        codeContent += part;
        return null;
      } else if (inBold && inItalic) {
        return <strong key={index}><em>{part}</em></strong>;
      } else if (inBold) {
        return <strong key={index}>{part}</strong>;
      } else if (inItalic) {
        return <em key={index}>{part}</em>;
      } else {
        // const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
        // const urls = part.match(urlRegex) || [];
        // let lastIndex = 0;
        // const elements = [];
        
        // for (const url of urls) {
        //   const index = part.indexOf(url, lastIndex);
        //   if (index > lastIndex) {
        //     elements.push(part.slice(lastIndex, index));
        //   }
        //   elements.push(
        //     <a 
        //       key={`url-${index}`} 
        //       href={url} 
        //       style={{color: 'white', textDecoration: 'underline'}} 
        //       target="_blank" 
        //       rel="noopener noreferrer"
        //     >
        //       {url}
        //     </a>
        //   );
        //   lastIndex = index + url.length;
        // }
        
        // if (lastIndex < part.length) {
        //   elements.push(part.slice(lastIndex));
        // }
        
        return part;
      }
    }
  }).filter(Boolean);
};

export const FormatMessage = ({ content, copyToClipboard, progressState, role }) => {
  const [imageLoaded, setImageLoaded] = useState({});

  const formatMessage = (message) => {
    if (message.content === '') {
      return (
        <div className="analyzing-container-message">
          <video 
            autoPlay 
            loop 
            muted 
            playsInline
            className="loader-video-message"
          >
            <source src="/loader.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {progressState && progressState !== 'null' && (
            <span className="shimmer-text">
              {progressState}
            </span>
          )}
        </div>
      );
    }

    if (typeof message.content === 'string' && message.content.startsWith('data:image/')) {
      return (
        <div className="image-message">
          <img 
            src={message.content}
            alt="Generated image"
            className="chat-image"
            style={{ width: '100%', maxWidth: '512px', height: 'auto' }}
            onLoad={() => setImageLoaded(prev => ({ ...prev, [message._id]: true }))}
            onError={(e) => {
              console.error('Image failed to load:', e);
              e.target.style.display = 'none';
              e.target.nextSibling.style.display = 'block';
            }}
          />
          <div className="base64-fallback" style={{display: 'none'}}>
            <p>Unable to render image. Base64 string preview:</p>
            <textarea 
              readOnly 
              value={`${message.content.substring(0, 100)}...`}
              onClick={(e) => e.target.select()}
            />
            <button onClick={() => copyToClipboard(message.content)}>
              Copy Full Base64
            </button>
          </div>
        </div>
      );
    }

    const content = typeof message.content === 'string' ? message.content : String(message.content);
    const lines = content.split('\n');
    let formattedContent = [];
    let inCodeBlock = false;
    let codeBlockContent = [];
    let codeLanguage = '';
    let inList = false;
    let listItems = [];
    let listType = null;
    let listCounter = 0;
    let inTable = false;
    let tableHeaders = [];
    let tableRows = [];
    let tableAlignments = [];

    const finishList = () => {
      if (inList) {
        formattedContent.push(
          React.createElement(listType, { key: `list-${formattedContent.length}` }, listItems)
        );
        inList = false;
        listItems = [];
        listType = null;
        listCounter = 0;
      }
    };

    const finishTable = () => {
      if (inTable && tableHeaders.length > 0) {
        formattedContent.push(
          <table key={`table-${formattedContent.length}`} className="markdown-table">
            <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th 
                    key={`th-${index}`}
                    style={{ 
                      textAlign: tableAlignments[index] || 'left',
                      padding: '8px',
                      borderBottom: '2px solid #0D00FF',
                      backgroundColor: 'rgba(13, 0, 255, 0.05)'
                    }}
                  >
                    {formatInlineElements(header.trim())}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRows.map((row, rowIndex) => (
                <tr key={`tr-${rowIndex}`}>
                  {row.map((cell, cellIndex) => (
                    <td 
                      key={`td-${rowIndex}-${cellIndex}`}
                      style={{ 
                        textAlign: tableAlignments[cellIndex] || 'left',
                        padding: '8px',
                        borderBottom: '1px solid #0D00FF'
                      }}
                    >
                      {formatInlineElements(cell.trim())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
        inTable = false;
        tableHeaders = [];
        tableRows = [];
        tableAlignments = [];
      }
    };

    const parseTableRow = (line) => {
      return line.split('|')
        .filter((cell, index, array) => index > 0 && index < array.length - 1)
        .map(cell => cell.trim());
    };

    const parseTableAlignments = (line) => {
      return line.split('|')
        .filter((cell, index, array) => index > 0 && index < array.length - 1)
        .map(cell => {
          cell = cell.trim();
          if (cell.startsWith(':') && cell.endsWith(':')) return 'center';
          if (cell.endsWith(':')) return 'right';
          return 'left';
        });
    };

    const processCodeBlock = (codeLines) => {
      const code = codeLines.join('\n');
      
      if (code.includes('const data =') && 
          code.includes('const config =') && 
          code.includes('type:') &&
          /type:\s*['"](?:line|bar|pie|area)['"]/.test(code)) {
        try {
          return <GraphRenderer code={code} />;
        } catch (error) {
          console.error('Error rendering graph:', error);
          const language = hljs.getLanguage(codeLanguage) ? codeLanguage : 'plaintext';
          const highlightedCode = hljs.highlight(code, { language }).value;
          return (
            <div className="code-block-wrapper">
              <Button 
                className="copy-code-button"
                onClick={() => copyToClipboard(code)}
              >
                <LuCopy size="15"/>
              </Button>
              <pre className="code-block">
                <code className={`language-${language || 'plaintext'}`} dangerouslySetInnerHTML={{ __html: highlightedCode }} />
              </pre>
            </div>
          );
        }
      }

      const language = hljs.getLanguage(codeLanguage) ? codeLanguage : 'plaintext';
      const highlightedCode = hljs.highlight(code, { language }).value;

      return (
        <div className="code-block-wrapper">
          <Button 
            className="copy-code-button"
            onClick={() => copyToClipboard(code)}
          >
            <LuCopy size="15"/>
          </Button>
          <pre className="code-block">
            <code className={`language-${language || 'plaintext'}`} dangerouslySetInnerHTML={{ __html: highlightedCode }} />
          </pre>
        </div>
      );
    };

    // Handle table rows first
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const trimmedLine = line.trim();

      // In your main loop, modify the code block section:
      if (trimmedLine.startsWith('```')) {
        finishList();
        if (inCodeBlock) {
          formattedContent.push(processCodeBlock(codeBlockContent));
          inCodeBlock = false;
          codeBlockContent = [];
          codeLanguage = '';
        } else {
          inCodeBlock = true;
          codeLanguage = trimmedLine.slice(3).trim();
        }
        continue;
      }

      if (line.includes('|')) {
        const cells = line.split('|').filter(cell => cell.trim().length > 0);
        if (cells.length > 0) {
          if (!inTable) {
            finishList(); // End any ongoing list
            inTable = true;
            tableHeaders = parseTableRow(line);
          } else if (line.includes('-')) {
            // This is the alignment row
            tableAlignments = parseTableAlignments(line);
          } else {
            tableRows.push(parseTableRow(line));
          }
          continue;
        }
      } else if (inTable) {
        finishTable();
      }



      if (inCodeBlock) {
        codeBlockContent.push(line);
        continue;
      }

      // Headers
      const headerMatch = line.match(/^(#{1,6})\s(.+)/);
      if (headerMatch) {
        finishList();
        finishTable();
        const level = headerMatch[1].length;
        formattedContent.push(
          React.createElement(
            `h${level}`, 
            { key: `header-${i}` }, 
            formatInlineElements(headerMatch[2])
          )
        );
        continue;
      }

      // Lists
      if (trimmedLine.startsWith('- ') || trimmedLine.startsWith('• ')) {
        finishTable();
        if (!inList || listType !== 'ul') {
          finishList();
          inList = true;
          listType = 'ul';
        }
        listItems.push(
          <li key={`li-${i}`}>
            {formatInlineElements(trimmedLine.slice(2))}
          </li>
        );
        continue;
      }//

      const orderedListMatch = trimmedLine.match(/^\d+\.(\d+)\.\s(.+)/);
      if (orderedListMatch) {
        finishTable();
        if (!inList || listType !== 'ol') {
          finishList();
          inList = true;
          listType = 'ol';
          listCounter = 0;
        }
        listCounter++;
        const [, , content] = orderedListMatch;
        listItems.push(
          <li key={`li-${i}`}>
            <strong>{listCounter}. </strong>
            {formatInlineElements(content)}
          </li>
        );
        continue;
      }

      const processURLs = (content) => {
        const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
        
        if (typeof content === 'string') {
          const urls = content.match(urlRegex) || [];
          let lastIndex = 0;
          const elements = [];
          
          for (const url of urls) {
            const index = content.indexOf(url, lastIndex);
            if (index > lastIndex) {
              elements.push(content.slice(lastIndex, index));
            }
            elements.push(
              <a 
                key={`url-${index}`} 
                href={url} 
                style={{color: role === 'user' ? 'white' : '#0D00FF', textDecoration: 'underline'}} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {url}
              </a>
            );
            lastIndex = index + url.length;
          }
          
          if (lastIndex < content.length) {
            elements.push(content.slice(lastIndex));
          }
          
          return elements.length > 0 ? elements : content;
        }
        
        return content;
      };

      //Regular paragraph
      // if (trimmedLine !== '') {
      //   finishList();
      //   finishTable();
      //   formattedContent.push(
      //     <p key={`p-${i}`}>
      //       {formatInlineElements(trimmedLine)}
      //     </p>
      //   );
      // }
      if (trimmedLine !== '') {
        finishList();
        finishTable();
        const formatted = formatInlineElements(trimmedLine);
        formattedContent.push(
          <p key={`p-${i}`}>
            {Array.isArray(formatted) 
              ? formatted.map(el => processURLs(el))
              : processURLs(formatted)}
          </p>
        );
      }
    }

    // Clean up any remaining blocks
    finishList();
    finishTable();
    if (inCodeBlock && codeBlockContent.length > 0) {
      formattedContent.push(processCodeBlock(codeBlockContent, codeLanguage));
    }

    return formattedContent;
  };

  return formatMessage({ content });
};

export default FormatMessage;