import React, { useState, useEffect } from 'react';
import { QuestionMarkCircledIcon, Cross2Icon, ArrowLeftIcon } from '@radix-ui/react-icons';
import * as Dialog from '@radix-ui/react-dialog';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaRocketchat } from "react-icons/fa";
import { Tooltip } from '@radix-ui/themes';
import { LuRocket } from "react-icons/lu";
import '../styles/AgentPopup.css';

const AgentPopup = ({ agent, onClose, onStartChat }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isStartingChat, setIsStartingChat] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const nameMap = {
    'o1': 'o1',
    'o1mini': 'o1 Mini',
    'gpt4': 'GPT 4',
    'gpt4o': 'GPT 4o',
    'gpt4omini': 'GPT 4o Mini',
    'claude3.5': 'Claude 3.5',
    'claudesonnet': 'Claude Sonnet',
    'claude-opus': 'Claude Opus',
    'claude-haiku': 'Claude Haiku',
    'mistral-large': 'Mistral AI Large',
    'llama3.1': 'Llama 3.1',
    'gemini1.5': 'Gemini 1.5',
    'cohere': 'Cohere',
    'stablediffusion': 'Stable Diffusion',
    'dall-e3': 'DALL-E 3'
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsVisible(true);
    });
    return () => setIsVisible(false);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 200);
  };

  const handleTestIt = async () => {
    setIsStartingChat(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/${agent._id}/chat`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      const agentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/agents/details/${agent._id}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      const formattedAgent = {
        ...agentResponse.data,
        entityType: 'agent',
        type: 'agent',
        icon: agent.icon,
        iconPath: `/images/all_icons/${agent.icon}`
      };

      const chatData = {
        chatId: response.data._id,
        agent: formattedAgent,
        isConversationStarted: true,
        currentMode: "agents"
      };

      setSelectedChat(chatData);

      navigate(`/home/agents/${response.data._id}`, { 
        replace: true,
        state: { 
          chatId: response.data._id,
          initialSelectedEntity: formattedAgent
        }
      });
    } catch (error) {
      console.error('Error starting agent chat:', error);
      setIsStartingChat(false);
    }
  };

  const handleUseTemplate = () => {
    const displayModelName = nameMap[agent.aiModel] || agent.aiModel;

    navigate('/agents/create', { 
      state: { 
        templateData: {
          description: agent.description,
          aiModel: displayModelName,
          mood: agent.mood * 100, // Convert back to 0-100 range
          icon: agent.icon,
          specialization: agent.specialization || [],
          tools: agent.tools || { webSearch: false }
        }
      }
    });
  };

  return (
    <>
      <div className={`agent-popup-overlay ${isVisible ? 'open' : ''}`} onClick={handleClose} />
      <div className={`agent-popup ${isVisible ? 'open' : ''}`}>
        <button className="close-button-agent" onClick={handleClose}>
          <Cross2Icon />
        </button>
        <div className="agent-popup-content">
          <div className="agent-info">
            <img
              src={`/images/all_icons/${agent.icon}`}
              alt={`${agent.name} icon`}
              className="agent-icon-popup"
            />
            <span className="agent-name">{agent.name}</span>
          </div>
          
          <div className="agent-section-content">
            <p>{agent.miniDescription}</p>
          </div>

          <div className="agent-description-popup">
            <p>{agent.description}</p>
          </div>

          <div className="agent-notes">
            <h3>What this agent is best at:</h3>
            <div className="agent-notes-links">
              <span className="agent-notes-link"><strong>Strength:</strong> {agent.strength1}</span>
              <span className="agent-notes-link"><strong>Tip:</strong> {agent.strength2}</span>
              <span className="agent-notes-link"><strong>Tip:</strong> {agent.strength3}</span>
            </div>
          </div>

          <div className="agent-action-buttons">
            <Tooltip content="Start a conversation">
              <button className="agent-action-button" onClick={handleTestIt}>
                <FaRocketchat />&nbsp; Test it
              </button>
            </Tooltip>

            <Tooltip content="Create an Agent using this one's Model and Prompt">
              <button className="agent-action-button" onClick={handleUseTemplate}>
                <LuRocket />&nbsp; Use as template
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentPopup;
