// FilesBanner.jsx
import React from 'react';
import { Tooltip, IconButton } from '@radix-ui/themes';
import { MdOutlineAttachFile } from "react-icons/md";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";

export const FilesBanner = ({ uploadedFiles, handleFileRemove, isUploading }) => {
  return (
    <div className="files-banner">
      {uploadedFiles.map((file, index) => (
        <div key={index} className="file-box">
          <button 
            className="file-delete-btn"
            onClick={(e) => {
              e.stopPropagation();
              handleFileRemove(index);
            }}
            disabled={isUploading}
          >
            ×
          </button>
          <span className="file-name">{file.name}</span>
          {isUploading ? (
            <div className="file-type uploading">
              <svg className="circular-progress" viewBox="0 0 36 36">
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#E6E6E6"
                  strokeWidth="2"
                />
                <path
                  className="progress"
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#0D00FF"
                  strokeWidth="2"
                />
              </svg>
              <span>Uploading...</span>
            </div>
          ) : (
            <span className="file-type">{file.type}</span>
          )}
        </div>
      ))}
    </div>
  );
};

// TalkingToBanner.jsx
export const TalkingToBanner = ({ selectedEntities }) => {
  return (
    <div className="talking-to-banner">
      Talking to{' '}
      {selectedEntities.map((entity, index) => (
        <React.Fragment key={entity._id || index}>
          <img 
            src={entity.entityType === 'agent' 
              ? `/images/all_icons/${entity.icon}`
              : `/images/all_icons/${entity.icon}`
            }
            alt={entity.name} 
            className="mention-icon"
          />
          {entity.name}
          {index < selectedEntities.length - 1 && ' and '}
        </React.Fragment>
      ))}
    </div>
  );
};

// MentionDropdown.jsx
export const MentionDropdown = ({ 
  dropdownRef, 
  input, 
  mentionIndex, 
  selectedItemRef,
  handleEntitySelect,
  getAllEntities,
  models,
  agents 
}) => {

  // Get filtered entities first
  const searchText = input.slice(input.lastIndexOf('@') + 1).toLowerCase();
  const filteredEntities = getAllEntities(models, agents).filter(entity =>
    entity.name.toLowerCase().includes(searchText)
  );
  
  return (
    <div className="mention-dropdown" ref={dropdownRef}>
      {filteredEntities.map((entity, index) => (
        <div
          key={entity._id}
          ref={index === mentionIndex ? selectedItemRef : null}
          className={`mention-item ${index === mentionIndex ? 'selected' : ''}`}
          onClick={() => handleEntitySelect(entity)}
        >
          <img 
            src={entity.iconPath}
            alt={entity.name} 
            className="mention-icon"
          />
          <span>@{entity.name}</span>
        </div>
      ))}
    </div>
  );
};

// InputButtons.jsx


export const InputButtons = ({
  fileInputRef,
  isUploading,
  handleFileUpload,
  isEnhancing,
  handleEnhancePrompt,
  handleSendClick,
  input,
  selectedEntity
}) => {
  return (
    <div className="input-buttons-wrapper">
      <div className="input-buttons-left">
        <Tooltip content="Import files">
          <IconButton radius="full" variant="soft">
            <button 
              className={`action-button ${isEnhancing ? 'enhancing' : ''}`}
              onClick={() => fileInputRef.current.click()}
              disabled={isUploading}
            >
              <MdOutlineAttachFile size="22"/>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              multiple
              onChange={handleFileUpload}
            />
          </IconButton>
        </Tooltip>
        <Tooltip content="Enhance your prompt">
          <IconButton radius="full" variant="soft">
            <button 
              className={`action-button enhance ${isEnhancing ? 'enhancing' : ''}`}
              onClick={handleEnhancePrompt}
              disabled={isEnhancing || isUploading}
            >
              <FaWandMagicSparkles size="22"/>
            </button>
          </IconButton>
        </Tooltip>
      </div>
      <div className="input-buttons-right">
        <Tooltip content="Send message">
          <IconButton radius="full">
            <button 
              className={`action-button ${isEnhancing ? 'enhancing' : ''}`}
              onClick={handleSendClick}
              disabled={!input.trim() || !selectedEntity || isUploading}
            >
              <IoIosSend size="20"/>
            </button>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};