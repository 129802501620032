import React, { useState, useEffect, useCallback } from 'react';
import { Card } from '../components/ui/base-components';
import { Button } from '../components/ui/base-components';
import { Select } from '@radix-ui/themes';
import { DateRangePicker } from '../components/ui/base-components';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from '../components/ui/charts';
import { MessageSquare, Users, FileText, Bot, RefreshCcw } from 'lucide-react';
import '../styles/Analytics.css';
import axios from 'axios';


const CACHE_KEY = 'analytics_cache';
const CACHE_DURATION = 30 * 60 * 1000;
const AUTO_REFRESH_INTERVAL = 120 * 1000;

const getEntityDisplayName = (entityId, entity) => {
  if (!entity) return '';
  
  // Handle model names
  const modelMapping = {
    'o1': 'o1',
    'o1mini': 'o1 Mini',
    'gpt4': 'GPT 4',
    'gpt4o': 'GPT 4o',
    'gpt4omini': 'GPT 4o Mini',
    'claude3.5': 'Claude 3.5',
    'claudesonnet': 'Claude Sonnet',
    'claude-opus': 'Claude Opus',
    'claude-haiku': 'Claude Haiku',
    'mistral-large': 'Mistral AI Large',
    'mistral-medium': 'Mistral AI Medium',
    'mistral-small': 'Mistral AI Small',
    'codestral': 'Codestral',
    'llama3.1': 'Llama 3.1',
    'gemini1.5': 'Gemini 1.5',
    'cohere': 'Cohere',
    'perplexity': 'Perplexity',
    'stablediffusion': 'Stable Diffusion',
    'dall-e3': 'DALL-E 3'
  };

  if (entityId.startsWith('model-')) {
    const modelKey = entity.replace('model-', '').toLowerCase();
    return modelMapping[modelKey] || entity;
  } else if (entityId.startsWith('agent-')) {
    return entity.replace('agent-', '');
  }
  return entity;
};

const Analytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [models, setModels] = useState([]);
  const [agents, setAgents] = useState([]);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0] // today
  });
  const [selectedEntity, setSelectedEntity] = useState('all');

  const fetchModels = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chatmodels`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const filteredModels = response.data.filter(model => model.name !== 'Multi Modal');
      setModels(filteredModels);
    } catch (error) {
      console.error('Error fetching chat models:', error);
      setError('Error fetching models');
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setError('Error fetching agents');
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchModels();
    fetchAgents();
  }, []);

  const getUsageEntityDisplayName = (entityName) => {
    const modelMapping = {
      'gpt4o': 'GPT 4o',
      'claude3_5': 'Claude 3.5',
      'o1': 'o1',
      'o1mini': 'o1 Mini',
      'gpt4': 'GPT 4',
      'gpt4omini': 'GPT 4o Mini',
      'claudesonnet': 'Claude Sonnet',
      'claude-opus': 'Claude Opus',
      'claude-haiku': 'Claude Haiku',
      'mistral-large': 'Mistral AI Large',
      'mistral-medium': 'Mistral AI Medium',
      'mistral-small': 'Mistral AI Small',
      'codestral': 'Codestral',
      'llama3.1': 'Llama 3.1',
      'gemini1.5': 'Gemini 1.5',
      'cohere': 'Cohere',
      'perplexity': 'Perplexity',
      'stablediffusion': 'Stable Diffusion',
      'dall-e3': 'DALL-E 3'
    };

    if (modelMapping[entityName]) {
      return modelMapping[entityName];
    }

    const agent = agents.find(a => a.name === entityName);
    if (agent) {
      return agent.name;
    }

    return entityName;
  };

  const getModelEndpoint = (model) => {
    switch (model.toLowerCase()) {
      case 'o1':
        return 'o1';
      case 'o1 mini':
        return 'o1mini';
      case 'gpt 4o mini':
        return 'gpt4omini';
      case 'gpt 4':
        return 'gpt4';
      case 'claude 3.5':
        return 'claude3.5';
      case 'claude sonnet':
        return 'claudesonnet';
      case 'claude opus':
        return 'claude-opus';
      case 'claude haiku':
        return 'claude-haiku';
      case 'mistral ai large':
        return 'mistral-large';
      case 'mistral ai medium':
        return 'mistral-medium';
      case 'mistral ai small':
        return 'mistral-small';
      case 'codestral':
        return 'codestral';
      case 'llama 3.1':
        return 'llama3.1';
      case 'gemini 1.5':
        return 'gemini1.5';
      case 'cohere':
        return 'cohere';
      case 'stable diffusion':
      case 'stablediffusion':
        return 'stablediffusion';
      case 'dall-e 3':
        return 'dall-e3';
      case 'dall-e3':
        return 'dall-e3';
      default:
        return model.toLowerCase().replace(' ', '');
    }
  };

  const getSelectedEntityValue = () => {
    if (!selectedEntity || selectedEntity === 'all') return 'all';
    
    if (selectedEntity.startsWith('model-')) {
      const modelName = selectedEntity.replace('model-', '');
      return `model-${getModelEndpoint(modelName)}`;
    }
    
    return selectedEntity;
  };

  const fetchAnalytics = useCallback(async (skipCache = false) => {
    try {
      const entityValue = getSelectedEntityValue();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/analytics/user-stats?` + 
        `startDate=${dateRange.start}&endDate=${dateRange.end}&entity=${entityValue}`, 
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      const data = await response.json();
      setAnalyticsData(data);
      setLastFetchTime(Date.now());
      setLoading(false);
    } catch (error) {
      console.error('Error fetching analytics:', error);
      setLoading(false);
    }
  }, [dateRange, selectedEntity]);

  useEffect(() => {
    fetchAnalytics();
  }, [fetchAnalytics, dateRange, selectedEntity]);

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      await fetchAnalytics(true);
    } catch (error) {
      console.error('Error refreshing analytics:', error);
    } finally {
      setRefreshing(false);
    }
  };

  const calculateDateDifference = () => {
    const start = new Date(dateRange.start);
    const end = new Date(dateRange.end);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getTopModels = () => {
    if (!analyticsData) return [];
    
    let entities = [];
    if (analyticsData.messagesByModel) {
      entities = [...entities, ...Object.entries(analyticsData.messagesByModel)
        .map(([name, value]) => ({ name, value }))
        .filter(entity => {
          return true;
        })];
    }
    if (analyticsData.agentUsage) {
      entities = [...entities, ...Object.entries(analyticsData.agentUsage)
        .map(([name, value]) => ({ name, value }))
        .filter(entity => {
          return true;
        })];
    }

    return entities
      .sort((a, b) => b.value - a.value)
      .slice(0, 3)
      .map((entity, index) => ({
        ...entity,
        color: index === 0 ? 'blue' : index === 1 ? 'green' : 'orange'
      }));
  };

  const getDailyActivity = () => {
    if (!analyticsData?.dailyActivity) return [];
    return analyticsData.dailyActivity.filter(activity => {
      const date = new Date(activity.date);
      return date >= new Date(dateRange.start) && date <= new Date(dateRange.end);
    });
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <div className="spinner" />
      </div>
    );
  }

  const getAllEntities = () => {
    const entities = [{ value: 'all', label: 'All Models and Agents' }];
    
    // Add models with a separator
    if (models.length > 0) {
      entities.push({ value: 'models-separator', label: '── Models ──', disabled: true });
      models.forEach(model => {
        entities.push({ 
          value: `model-${model.name.toLowerCase()}`, 
          label: getEntityDisplayName(`model-${model.name}`, model.name)
        });
      });
    }
    
    // Add agents with a separator
    if (agents.length > 0) {
      entities.push({ value: 'agents-separator', label: '── Agents ──', disabled: true });
      agents.forEach(agent => {
        entities.push({ 
          value: `agent-${agent.name}`, 
          label: agent.name
        });
      });
    }
    
    return entities;
  };

  const getSelectedEntityName = () => {
    if (!selectedEntity || selectedEntity === 'all') return 'All Models and Agents';
    
    return getEntityDisplayName(selectedEntity, selectedEntity.replace(/^(model-|agent-)/, ''));
  };

  return (
    <div className="analytics">
      <h1>Overview</h1>
      <div className="analytics-header">
        <DateRangePicker
          startDate={dateRange.start}
          endDate={dateRange.end}
          onDatesChange={setDateRange}
        />
        <Select.Root value={selectedEntity} onValueChange={setSelectedEntity} className="analytics-model-select">
          <Select.Trigger placeholder="Filter by AI Models" />
          <Select.Content position="popper" sideOffset={5} style={{ maxHeight: '500px' }}>
            {getAllEntities().map(entity => (
              <Select.Item 
                key={entity.value} 
                value={entity.value}
                disabled={entity.disabled}
                className="analytics-model-filter"
              >
                {entity.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
        <Button onClick={handleRefresh} disabled={refreshing}>
          <RefreshCcw className={refreshing ? 'icon-spin' : ''} size={16} />
          {refreshing ? 'Refreshing...' : 'Refresh Data'}
        </Button>
      </div>

      <div className="analytics-stats">
        <Card className="analytics-stat-card analytics-stat-blue">
          <div className="analytics-stat-content">
            <div className="analytics-stat-icon">
              <MessageSquare size={24} />
            </div>
            <div className="analytics-stat-text">
              <h3>{analyticsData?.totalMessages || 0}</h3>
               <p>Total Messages for last {calculateDateDifference()} days and for {getSelectedEntityName()}</p>
            </div>
          </div>
          <div className="analytics-wave analytics-wave-blue" />
        </Card>

        <Card className="analytics-stat-card analytics-stat-green">
          <div className="analytics-stat-content">
            <div className="analytics-stat-icon">
              <Users size={24} />
            </div>
            <div className="analytics-stat-text">
              <h3>{analyticsData?.totalChats || 0}</h3>
              <p>Total Chats Created</p>
            </div>
          </div>
          <div className="analytics-wave analytics-wave-green" />
        </Card>

        <Card className="analytics-stat-card analytics-stat-purple">
          <div className="analytics-stat-content">
            <div className="analytics-stat-icon">
              <FileText size={24} />
            </div>
            <div className="analytics-stat-text">
              <h3>{analyticsData?.totalDocuments || 0}</h3>
              <p>Total Documents</p>
            </div>
          </div>
          <div className="analytics-wave analytics-wave-purple" />
        </Card>

        <Card className="analytics-stat-card analytics-stat-orange">
          <div className="analytics-stat-content">
            <div className="analytics-stat-icon">
              <Bot size={24} />
            </div>
            <div className="analytics-stat-text">
              <h3>{analyticsData?.totalAgents || 0}</h3>
              <p>Personal Agents Active</p>
            </div>
          </div>
          <div className="analytics-wave analytics-wave-orange" />
        </Card>
      </div>

      <div className="analytics-charts">
        <Card className="analytics-chart-card">
          <h3 className="analytics-chart-title">Daily Message Activity for {getSelectedEntityName()}</h3>
          <div className="analytics-chart-container">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={getDailyActivity()}>
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => new Date(date).toLocaleDateString('en-US', { weekday: 'short' })}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value) => [`${value} messages`, 'Messages']}
                  labelFormatter={(date) => new Date(date).toLocaleDateString('en-US', { 
                    weekday: 'long', 
                    year: 'numeric', 
                    month: 'long', 
                    day: 'numeric' 
                  })}
                />
                <Bar 
                  dataKey="messages" 
                  fill="#3182ce"
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>

        <Card className="analytics-usage-card">
          <h3 className="analytics-chart-title">Usage of Model</h3>
          <div className="analytics-model-list">
            {getTopModels().map((model, index) => {
              const displayName = getUsageEntityDisplayName(model.name);
              console.log('Model:', model.name, 'Display name:', displayName); // Debug log
              return (
                <div key={model.name} className="analytics-model-usage">
                  <div className="analytics-model-header">
                    <span className="analytics-model-name">{displayName}</span>
                    <span className="analytics-model-value">{model.value}</span>
                  </div>
                  <div className="analytics-model-bar">
                    <div 
                      className={`analytics-model-progress analytics-model-progress-${model.color}`}
                      style={{ 
                        width: `${(model.value / getTopModels()[0].value) * 100}%`
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Analytics;