import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Eye, EyeOff, Check } from 'lucide-react';
import axios from 'axios';
import '../styles/Login.css';
import { toast, Bounce } from 'react-toastify';

const Register = () => {
  const [step, setStep] = useState(1);
  const [credentials, setCredentials] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/register`, credentials);
      if (response.data.companyId) {
        localStorage.setItem('companyId', response.data.companyId);
      }
      handleLogin();
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred during registration');
    }
  };

  const handleLogin = async () => {
    const success = await login({ email: credentials.email, password: credentials.password });
    if (success) {
      navigate('/home');
      toast.success('Login successful!', {
        theme: "colored",
      });
    } else {
      setError('Unable to log in. Please try logging in manually.');
    }
  };

  // return (
  //   <div className="login-container">
  //     <form onSubmit={handleSubmit} className="login-form">
  //       <img src="/calk-name.png" alt="Calk Logo" className="logo" />

  //       <div className="input-group">
  //         <label htmlFor="firstName">First Name</label>
  //         <input
  //           type="text"
  //           id="firstName"
  //           name="firstName"
  //           value={credentials.firstName}
  //           onChange={handleChange}
  //           placeholder="Your First Name"
  //           required
  //         />
  //       </div>
  //       <div className="input-group">
  //         <label htmlFor="lastName">Last Name</label>
  //         <input
  //           type="text"
  //           id="lastName"
  //           name="lastName"
  //           value={credentials.lastName}
  //           onChange={handleChange}
  //           placeholder="Your Last Name"
  //           required
  //         />
  //       </div>
  //       <div className="input-group">
  //         <label htmlFor="email">Work Email</label>
  //         <input
  //           type="email"
  //           id="email"
  //           name="email"
  //           value={credentials.email}
  //           onChange={handleChange}
  //           placeholder="Email"
  //           required
  //         />
  //       </div>
  //       <div className="input-group">
  //         <label htmlFor="password">Password</label>
  //         <div className="password-input">
  //           <input
  //             type={showPassword ? "text" : "password"}
  //             id="password"
  //             name="password"
  //             value={credentials.password}
  //             onChange={handleChange}
  //             placeholder="Password"
  //             required
  //           />
  //           <button
  //             type="button"
  //             onClick={() => setShowPassword(!showPassword)}
  //             className="password-toggle"
  //           >
  //             {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
  //           </button>
  //         </div>
  //       </div>
  //       <button type="submit" className="submit-button">Create account</button>
  //       <p className="form-link">
  //         Already have an account? <Link to="/login">Log in</Link>
  //       </p>

  //     </form>
  //   </div>
  // );
  return (
    <div className="login-container">
      <div className="login-left">
        <img src="/calk-name.png" alt="Calk Logo" className="logo" />
        <div className="testimonial">
          <p className="testimonial-quote">
            "The best is yet to come."
          </p>
          <p className="testimonial-author">Calk Team</p>
        </div>
      </div>
      <div className="login-right">
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Create your account</h2>
          <p className="login-subtitle">Enter your details below to get started!</p>

          <div className="input-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={credentials.firstName}
              onChange={handleChange}
              placeholder="Your First Name"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={credentials.lastName}
              onChange={handleChange}
              placeholder="Your Last Name"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Work Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="password-toggle"
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>
          <button type="submit" className="submit-button">Create account</button>
          <p className="form-link">
            Already have an account? <Link to="/login">Log in</Link>
          </p>
          
          <div className="policies">
            <a href="https://www.calk-ai.com/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
            <a href="https://www.calk-ai.com/terms-of-use" rel="noopener noreferrer" target="_blank">Terms of Service</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

        // <p className="form-link">
        //   <Link to="/pricing">Back to Pricing</Link>
        // </p>
